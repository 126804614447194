import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getExamType = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getExamType/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getExamType - " + error.message)
            console.log('error caught in service : getExamType')
        },
    );

export const getExamDetails = (academicPeriodId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getExamDetails/${getLicenseKey}/${academicPeriodId}`,
        response => {
            success(response)
        },
        error => {
            failure("getExamDetails - " + error.message)
            console.log('error caught in service : getExamDetails')
        },
    );

    export const addExamTimeTable = (examTimeTablePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

        api.post(getAPIURL() + `addExamTimeTable`, examTimeTablePostData,
            response => {
                success(response)
            },
            error => {
                failure(error.message)
                console.log('error caught in service : addExamTimeTable')
            }
        );

export const addExamDetails = (
    examDetailsPostData: any,
    success: (arg0: AxiosResponse<any, any>) => any,
    failure: (arg0: any) => void,
) =>
    api.post(
        getAPIURL() + `addExamDetails`,
        examDetailsPostData,
        (response) => {
            success(response);
        },
        (error) => {
            failure(error.message);
            console.log('error caught in service : addExamDetails');
        },
    );

export const updateExamDetails = (
    examDetailsPostData: any,
    success: (arg0: AxiosResponse<any, any>) => any,
    failure: (arg0: any) => void,
) =>
    api.post(
        getAPIURL() + `updateExamDetails`,
        examDetailsPostData,
        (response) => {
            success(response);
        },
        (error) => {
            failure(error.message);
            console.log('error caught in service : updateExamDetails');
        },
    );

export const deleteExamDetails = (
    examDetailsPostData: any,
    success: (arg0: AxiosResponse<any, any>) => any,
    failure: (arg0: any) => void,
) =>
    api.post(
        getAPIURL() + `deleteExamDetails`,
        examDetailsPostData,
        (response) => {
            success(response);
        },
        (error) => {
            failure(error.message);
            console.log('error caught in service : deleteExamDetails');
        },
    );

export const getExamHall = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getExamHall/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getExamHall - " + error.message)
            console.log('error caught in service : getExamHall')
        },
    );

    export const getExamTimeTable = (academicPeriodId: string, examTypeId: string, standardDetailsId: string, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

        api.get(getAPIURL() + `getExamTimeTable/${getLicenseKey}/${academicPeriodId}/${examTypeId}/${standardDetailsId}`,
            response => {
                success(response)
            },
            error => {
                failure("getExamTimeTable - " + error.message)
                console.log('error caught in service : getExamTimeTable')
            },
        );