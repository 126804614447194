import React, { useEffect, useState } from 'react';
import { getStudentTodayAttendanceReport } from '../../../../services/dashboard.service';
import { toasts } from '../../../../services/toast.service';
import useSortableData from '../../../../hooks/useSortableData';
import useDarkMode from '../../../../hooks/useDarkMode';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';
import PaginationButtons, { dataPagination } from '../../../../components/PaginationButtons';
import { priceFormat } from '../../../../helpers/helpers';
import Progress from '../../../../components/bootstrap/Progress';
import Button from '../../../../components/bootstrap/Button';
import NoDataMsg from '../../../../common/components/NoDataMsg';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../../components/bootstrap/Modal';
import classNames from 'classnames';

const TotalPresentAbsenteesReport = () => {

	useEffect(() => {
		getStudentTodayAttendanceReportList();
	}, []);

	const [dataStatus, setDataStatus] = useState(false);
	const [studentsReportData, setStudentsReportData] = useState<any>([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(3);
	const { items, requestSort, getClassNamesFor } = useSortableData(studentsReportData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const [noDataMsg, setNoDataMsg] = useState('')

	const { themeStatus, darkModeStatus } = useDarkMode();

	const handleOpenModal = () => {
		setIsModalOpen(true);
	};

	function getStudentTodayAttendanceReportList() {
		setDataStatus(false);
		getStudentTodayAttendanceReport(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentTodayAttendanceReport;
					if (data != undefined) {
						setDataStatus(true);
						setStudentsReportData(data);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
					} else {
						setDataStatus(true);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setDataStatus(true);
					// toasts(response.data.message, 'Error');
					setStudentsReportData([]);
					setNoDataMsg(response.data.message)

				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setDataStatus(true);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}

	return (
		<>
			<Card stretch data-tour='list'>
				<CardHeader className='d-flex justify-content-between'>
					<CardLabel icon='Menu' iconColor='info' >
						<CardTitle tag='div' className='h5'>
							Present/Absentees Report
						</CardTitle>
					</CardLabel>
					<CardActions>
						<Button
							color='info'
							isLink
							icon='Summarize'
							onClick={handleOpenModal}>
							Detailed Report
						</Button>
					</CardActions>
				</CardHeader>
				<CardBody className='table-responsive' isScrollable>
					<table className='table table-modern table-hover '>
						<thead>
							<tr>
								<th
									onClick={() => requestSort('standardName')}
									className='cursor-pointer text-decoration-underline'>
									Class{' '}
								</th>
								<th
									onClick={() => requestSort('TotalStudents')}
									className='cursor-pointer text-decoration-underline'>
									Total Students{' '}
								</th>
								<th
									onClick={() => requestSort('totalPresent')}
									className='cursor-pointer text-decoration-underline'>
									Total Present{' '}
								</th>
								<th
									onClick={() => requestSort('TotalAbsent')}
									className='cursor-pointer text-decoration-underline'>
									Total Absent{' '}
								</th>
								<th
									onClick={() => requestSort('ofLoad')}
									style={{
										minWidth: 100,
									}}
									className='cursor-pointer text-decoration-underline'>
									Percentage%{' '}
								</th>
							</tr>
						</thead>
						<tbody>
							{studentsReportData != '' ? <>
								{onCurrentPageData.map((i: any) => (
									<tr key={i.sectionDetailsId}>
										<td>{i.standardName} - {i.sectionName}</td>
										<td>{i.totalStudents}</td>
										<td>{i.totalPresent}</td>
										<td>{i.totalAbsent}</td>
										<td>
											<div className='d-flex align-items-center'>
												<div className='flex-shrink-0 me-3'>
													{`${i.presentPercentage}%`}
												</div>
												<Progress
													className='flex-grow-1'
													isAutoColor
													value={i.presentPercentage}
													style={{
														height: 5,
													}}
												/>
											</div>
										</td>
									</tr>
								))}
							</> : <NoDataMsg columnsCount={5} msg={noDataMsg} />}
						</tbody>
					</table>
				</CardBody>
				<PaginationButtons
					data={items}
					label='items'
					setCurrentPage={setCurrentPage}
					currentPage={currentPage}
					perPage={perPage}
					setPerPage={setPerPage}
				/>
			</Card>

			<Modal
				setIsOpen={setIsModalOpen}
				isOpen={isModalOpen}
				titleId='presentAbsenteesReport'
				isStaticBackdrop
				isScrollable
				size='lg'>
				<ModalHeader
					className="'modal-header'"
					setIsOpen={() => {
						setIsModalOpen(false);
					}}
					onClick={() => setIsModalOpen(false)}>
					<ModalTitle id='presentAbsenteesReport'>
						Present Absentees Report
					</ModalTitle>
				</ModalHeader>

				<ModalBody>
					<div className='col-lg-5'></div>
					<div className='col-lg-12'>
						<table className='table table-modern table-hover'>
							<thead>
								<tr>
									<th className={classNames('bg-l25-info fs-6', { 'bg-l10-info': !darkModeStatus, 'bg-lo25-info': darkModeStatus })}>
										STANDARD
									</th>
									<th className={classNames('bg-l25-info fs-6', { 'bg-l10-info': !darkModeStatus, 'bg-lo25-info': darkModeStatus })}>
										TOTAL STUDENTS
									</th>
									<th className={classNames('bg-l25-info fs-6', { 'bg-l10-info': !darkModeStatus, 'bg-lo25-info': darkModeStatus })}>
										TOTAL PRESENT
									</th>
									<th className={classNames('bg-l25-info fs-6', { 'bg-l10-info': !darkModeStatus, 'bg-lo25-info': darkModeStatus })}>
										TOTAL ABSENT
									</th>
									<th className={classNames('bg-l25-info fs-6', { 'bg-l10-info': !darkModeStatus, 'bg-lo25-info': darkModeStatus })}>
										Percentage%
									</th>
								</tr>
							</thead>
							<tbody>
								{studentsReportData != '' ?
									<>
										{studentsReportData.map((i: any) => (
											<tr key={i.sectionDetailsId}>
												<td>{i.standardName}</td>
												<td>{i.totalStudents}</td>
												<td>{i.totalPresent}</td>
												<td>{i.totalAbsent}</td>
												<td>{i.presentPercentage}</td>
											</tr>
										))}
									</> :
									<NoDataMsg columnsCount={5} msg={noDataMsg} />}
							</tbody>
						</table>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
};

export default TotalPresentAbsenteesReport;
