import React, { useContext, useState } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import Page from '../../layout/Page/Page';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Button from '../bootstrap/Button';
import { profilePic, profilePicUpload } from '../../services/common.service';
import PlaceholderImage from '../extras/PlaceholderImage';
import { useFormik } from 'formik';
import { getLicenseKey } from '../../services/application.settings';
import AuthContext from '../../contexts/authContext';
import { showLoader } from '../../services/loader.services';
import { addEventDetails } from '../../services/sportsManagement.service';
import AlertService from '../../services/AlertService';

const EventDetails = () => {
	const { userAccountId, userTypeId } = useContext(AuthContext);
	const [selectedFile1, setSelectedFile1] = useState<any>();
	const [selectedFile2, setSelectedFile2] = useState<any>();
	const [selectedFile3, setSelectedFile3] = useState<any>();
	const [selectedFile4, setSelectedFile4] = useState<any>();

	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);
	const [isNavigate, setIsNavigate] = useState<any>(null);

	const [eventName, setEventname] = useState<any>();

	const addEventForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			eventName: '',
			dateOfEvent: '',
		},
		validate: (values: any) => {
			const errors: {
				eventName?: string;
				dateOfEvent?: string;
			} = {};
			if (!eventName) {
				errors.eventName = '';
			}
			return errors;
		},
		validateOnChange: true,
		onSubmit: (values) => {},
	});

	const handleFileChange1 = (e: any) => {
		if (e.target.files && e.target.files[0]) {
			setSelectedFile1(e.target.files[0]);
		}
	};

	const handleFileChange2 = (e: any) => {
		if (e.target.files && e.target.files[0]) {
			setSelectedFile2(e.target.files[0]);
		}
	};

	const handleFileChange3 = (e: any) => {
		if (e.target.files && e.target.files[0]) {
			setSelectedFile3(e.target.files[0]);
		}
	};

	const handleFileChange4 = (e: any) => {
		if (e.target.files && e.target.files[0]) {
			setSelectedFile4(e.target.files[0]);
		}
	};

	const handleDeleteAvatar1 = () => {
		setSelectedFile1(null);
		const fileInput = document.getElementById('fileInput') as HTMLInputElement;
		if (fileInput) {
			fileInput.value = '';
		}
	};

	const handleDeleteAvatar2 = () => {
		setSelectedFile2(null);
		const fileInput = document.getElementById('fileInput') as HTMLInputElement;
		if (fileInput) {
			fileInput.value = '';
		}
	};

	const handleDeleteAvatar3 = () => {
		setSelectedFile3(null);
		const fileInput = document.getElementById('fileInput') as HTMLInputElement;
		if (fileInput) {
			fileInput.value = '';
		}
	};

	const handleDeleteAvatar4 = () => {
		setSelectedFile4(null);
		const fileInput = document.getElementById('fileInput') as HTMLInputElement;
		if (fileInput) {
			fileInput.value = '';
		}
	};

	function onUpload() {
		showLoader(true);
		const filedata = new FormData();
		filedata.append('licenseKey', getLicenseKey.toString());
		filedata.append('staffDetailsId', userAccountId);
		filedata.append('eventName', addEventForm.values.eventName);
		filedata.append('dateOfEvent', addEventForm.values.dateOfEvent);
		filedata.append('noOfImage', '4');
		filedata.append('imagePath1', selectedFile1, selectedFile1.name);
		filedata.append('imagePath2', selectedFile2, selectedFile2.name);
		filedata.append('imagePath3', selectedFile3, selectedFile3.name);
		filedata.append('imagePath4', selectedFile4, selectedFile4.name);

		addEventDetails(
			filedata,
			(response) => {
				if (response.data.success == true) {
					showLoader(false);
					setAlertStatus({ message: response.data.message, type: 'success' });
					setIsOpen(true);
					closeAndReset()
				} else {
					showLoader(false);
					setAlertStatus({ message: response.data.message, type: 'warning' });
					setIsOpen(true);
				}
			},
			(error) => {
				showLoader(false);
				setAlertStatus({ message: 'Profile pic not upload', type: 'error' });
				setIsOpen(true);
				setIsNavigate(null);
			},
		);
	}

	function closeAndReset(){
		addEventForm.resetForm()
	}



	return (
		<PageWrapper title='Event Details'>
			<Page container='fluid'>
				<Card>
					<CardHeader borderSize={1}>
						<CardLabel icon='List' iconColor='info' className='col-lg-5'>
							<CardTitle tag='div' className='h5'>
								Event Details
							</CardTitle>
						</CardLabel>
					</CardHeader>
					<CardBody className='table-responsive'>
						<div className='row g-4'>
							<div className='col-3'>
								<FormGroup id='eventName' label='Event Name' isFloating>
									<Input
										placeholder='Enter Event Name'
										onChange={addEventForm.handleChange}
										value={addEventForm.values.eventName}
										onBlur={addEventForm.handleBlur}
										isValid={addEventForm.isValid}
										isTouched={addEventForm.touched.eventName}
										invalidFeedback={addEventForm.errors.eventName}
									/>
								</FormGroup>
							</div>
							<div className='col-3'>
								<FormGroup id='dateOfEvent' label='Event Date' isFloating>
									<Input
										placeholder='Event Date'
										type='date'
										onChange={addEventForm.handleChange}
										value={addEventForm.values.dateOfEvent}
									/>
								</FormGroup>
							</div>
						</div>
						<br />
						<div className='row'>
							<div className='col-3 g-2'>
								{selectedFile1 ? (
									<img
										src={URL.createObjectURL(selectedFile1)}
										alt=''
										width={128}
										height={128}
										className='mx-auto d-block img-fluid mb-3 rounded'
									/>
								) : (
									<PlaceholderImage
										width={128}
										height={128}
										className='mx-auto d-block img-fluid mb-3 rounded'
									/>
								)}
								<Input
									className='ms-3'
									id='fileInput'
									type='file'
									autoComplete='photo'
									ariaLabel='Upload image file'
									onChange={handleFileChange1}
								/>
								<div className='col-12 mt-2 d-flex justify-content-center'>
									<Button
										color='dark'
										isLight
										icon='Delete'
										onClick={handleDeleteAvatar1}>
										Delete Avatar
									</Button>
								</div>
							</div>
							<div className='col-3 g-2'>
								{selectedFile2 ? (
									<img
										src={URL.createObjectURL(selectedFile2)}
										alt=''
										width={128}
										height={128}
										className='mx-auto d-block img-fluid mb-3 rounded'
									/>
								) : (
									<PlaceholderImage
										width={128}
										height={128}
										className='mx-auto d-block img-fluid mb-3 rounded'
									/>
								)}
								<Input
									className='ms-3'
									id='fileInput'
									type='file'
									autoComplete='photo'
									ariaLabel='Upload image file'
									onChange={handleFileChange2}
								/>
								<div className='col-12 mt-2 d-flex justify-content-center'>
									<Button
										color='dark'
										isLight
										icon='Delete'
										onClick={handleDeleteAvatar2}>
										Delete Avatar
									</Button>
								</div>
							</div>
							<div className='col-3 g-2'>
								{selectedFile3 ? (
									<img
										src={URL.createObjectURL(selectedFile3)}
										alt=''
										width={128}
										height={128}
										className='mx-auto d-block img-fluid mb-3 rounded'
									/>
								) : (
									<PlaceholderImage
										width={128}
										height={128}
										className='mx-auto d-block img-fluid mb-3 rounded'
									/>
								)}
								<Input
									className='ms-3'
									id='fileInput'
									type='file'
									autoComplete='photo'
									ariaLabel='Upload image file'
									onChange={handleFileChange3}
								/>
								<div className='col-12 mt-2 d-flex justify-content-center'>
									<Button
										color='dark'
										isLight
										icon='Delete'
										onClick={handleDeleteAvatar3}>
										Delete Avatar
									</Button>
								</div>
							</div>
							<div className='col-3 g-2'>
								{selectedFile4 ? (
									<img
										src={URL.createObjectURL(selectedFile4)}
										alt=''
										width={128}
										height={128}
										className='mx-auto d-block img-fluid mb-3 rounded'
									/>
								) : (
									<PlaceholderImage
										width={128}
										height={128}
										className='mx-auto d-block img-fluid mb-3 rounded'
									/>
								)}
								<Input
									className='ms-3'
									id='fileInput'
									type='file'
									autoComplete='photo'
									ariaLabel='Upload image file'
									onChange={handleFileChange4}
								/>
								<div className='col-12 mt-2 d-flex justify-content-center'>
									<Button
										color='dark'
										isLight
										icon='Delete'
										onClick={handleDeleteAvatar4}>
										Delete Avatar
									</Button>
								</div>
							</div>
							<br />
						</div>
						<br />
						<div style={{ marginLeft: '1000px' }} className='g-2'>
							<Button color='info' type='submit' onClick={onUpload}>
								Submit
							</Button>
						</div>

						{/* <div className='row '>
							<div className='col-4 g-4'>
								<div className='col-8'>
									<div className='row g-4 align-items-center'>
										<div className='col-lg-4 ms-5'>
											{selectedFile ? (
												<img
													style={{ borderRadius: '50%' }}
													src={URL.createObjectURL(selectedFile)}
													alt='Description'
													height={110}
													width={110}
													className='mx-auto d-block  mb-3 '
												/>
											) : (
												// <img
												// 	style={{ borderRadius: '50%' }}
												// 	src={profilePic(1)}
												// 	height='110'
												// 	width='110'
												// 	className='border border-danger'
												// />
												<PlaceholderImage
													width={250}
													height={250}
													className='mx-auto d-block img-fluid mb-3 rounded'
												/>
											)}
										</div>
										<div className='col-12'>
											<div className='row g-4'>
												<div className='col-12'>
													<Input
														id='fileInput'
														type='file'
														autoComplete='photo'
														ariaLabel='Upload image file'
														onChange={handleFileChange}
													/>
												</div>
												<div className='col-12 mt-2'>
													<Button
														color='dark'
														isLight
														icon='Delete'
														onClick={handleDeleteAvatar}>
														Delete Avatar
													</Button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='col-4 g-4'>
								<div className='col-12'>
									<div className='row g-4 align-items-center'>
										<div className='col-lg-4 ms-5'>
											{selectedFile ? (
												<img
													style={{ borderRadius: '50%' }}
													src={URL.createObjectURL(selectedFile)}
													height='110'
													width='110'
												/>
											) : (
												<img
													style={{ borderRadius: '50%' }}
													src={profilePic(1)}
													height='110'
													width='110'
												/>
											)}
										</div>
										<div className='col-12'>
											<div className='row g-4'>
												<div className='col-8'>
													<Input
														id='fileInput'
														type='file'
														autoComplete='photo'
														ariaLabel='Upload image file'
														onChange={handleFileChange}
													/>
												</div>
												<div className='col-12 mt-2'>
													<Button
														color='dark'
														isLight
														icon='Delete'
														onClick={handleDeleteAvatar}>
														Delete Avatar
													</Button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='col-4 g-4'>
								<div className='col-12'>
									<div className='row g-4 align-items-center'>
										<div className='col-lg-3 ms-5'>
											{selectedFile ? (
												<img
													style={{ borderRadius: '50%' }}
													src={URL.createObjectURL(selectedFile)}
													height='110'
													width='110'
												/>
											) : (
												<img
													style={{ borderRadius: '50%' }}
													src={profilePic(1)}
													height='110'
													width='110'
												/>
											)}
										</div>
										<div className='col-12'>
											<div className='row g-4'>
												<div className='col-8'>
													<Input
														id='fileInput'
														type='file'
														autoComplete='photo'
														ariaLabel='Upload image file'
														onChange={handleFileChange}
													/>
												</div>
												<div className='col-12 mt-2'>
													<Button
														color='dark'
														isLight
														icon='Delete'
														onClick={handleDeleteAvatar}>
														Delete Avatar
													</Button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='col-4 g-4'>
								<div className='col-12'>
									<div className='row g-3 align-items-center'>
										<div className='col-lg-4 ms-5 md-3'>
											{selectedFile ? (
												<img
													style={{ borderRadius: '50%' }}
													src={URL.createObjectURL(selectedFile)}
													height='110'
													width='110'
												/>
											) : (
												<img
													style={{ borderRadius: '50%' }}
													src={profilePic(1)}
													height='110'
													width='110'
												/>
											)}
										</div>
										<div className='col-12 md-3'>
											<div className='row g-4'>
												<div className='col-8'>
													<Input
														id='fileInput'
														type='file'
														autoComplete='photo'
														ariaLabel='Upload image file'
														onChange={handleFileChange}
													/>
												</div>
												<div className='col-8 mt-2'>
													<Button
														color='dark'
														isLight
														icon='Delete'
														onClick={handleDeleteAvatar}>
														Delete Avatar
													</Button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div> */}
					</CardBody>
				</Card>
			</Page>
			<AlertService
				setIsOpen={setIsOpen}
				isOpen={isOpen}
				alertStatus={alertStatus}
				isNavigate={isNavigate}
			/>
		</PageWrapper>
	);
};

export default EventDetails;
