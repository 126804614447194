import React, { useEffect, useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Page from '../../../../layout/Page/Page';
import Button from '../../../../components/bootstrap/Button';
import { toasts } from '../../../../services/toast.service';
import Icon from '../../../../components/icon/Icon';
import useSortableData from '../../../../hooks/useSortableData';
import PaginationButtons, { dataPagination } from '../../../../components/PaginationButtons';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../../components/bootstrap/Modal';
import AlertService from '../../../../services/AlertService';
import { getNotSubmittedStudentAttendanceReport } from '../../../../services/dashboard.service';
import NoDataMsg from '../../../../common/components/NoDataMsg';
import { convertDateToEpoch, getCurrentDateAndTime } from '../../../../services/common.service';
import classNames from 'classnames';
import useDarkMode from '../../../../hooks/useDarkMode';

const AttendanceNotSubmitted = () => {
	useEffect(() => {
		handleOpenModal();
	}, []);

	const [dataStatus, setDataStatus] = useState(false);
	const [attendanceData, setAttendanceData] = useState<any>([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(5);
	const { items, requestSort, getClassNamesFor } = useSortableData(attendanceData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);
	const [isLoader, setIsLoader] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);

	const [noDataMsg, setNoDataMsg] = useState('')
	const { themeStatus, darkModeStatus } = useDarkMode();

	function handleOpenModal() {
		let todayDate = getCurrentDateAndTime('date')
		let today = convertDateToEpoch(todayDate)
		getNotSubmittedStudentAttendanceReportList(today);
	};

	function modalOpen() {
		handleOpenModal()
		setIsModalOpen(true);
	}

	function getNotSubmittedStudentAttendanceReportList(today: any) {
		setDataStatus(false);
		getNotSubmittedStudentAttendanceReport(today,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.notSubmittedStudentAttendanceReport;
					if (data != undefined) {
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
						setIsLoader(false);
						setDataStatus(true);
						setAttendanceData(data);
					} else {
						setDataStatus(true);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, 'Error');
					setAttendanceData([]);
					setNoDataMsg(response.data.message)
					setDataStatus(true);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setDataStatus(true);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}

	return (
		<>
			<Card data-tour='list'>
				<CardHeader className='d-flex justify-content-between'>
					<CardLabel icon='list' iconColor='info' >
						<CardTitle tag='div' className='h5'>
							Attendance Not Submitted
						</CardTitle>
					</CardLabel>
					<CardActions>
						<Button
							color='info'
							isLink
							icon='Summarize'
							onClick={modalOpen}>
							Detailed Report
						</Button>
					</CardActions>
				</CardHeader>
				<CardBody className='table-responsive' isScrollable>
					<table className='table table-modern table-hover '>
						<thead>
							<tr>
								<th
									scope='col'
									onClick={() => requestSort('standardName')}
									className='cursor-pointer text-decoration-underline'>
									Standard{' '}
									<Icon
										size='lg'
										className={getClassNamesFor('standardName')}
										icon='FilterList'
									/>
								</th>
								<th
									scope='col'
									onClick={() => requestSort('sectionName')}
									className='cursor-pointer text-decoration-underline'>
									Section{' '}
									<Icon
										size='lg'
										className={getClassNamesFor('sectionName')}
										icon='FilterList'
									/>
								</th>
							</tr>
						</thead>
						<tbody>
							{attendanceData != '' ? <>
								{onCurrentPageData.map((i: any, index: any) => (
									<tr key={index}>
										<td>{i.standardName}</td>
										<td>{i.sectionName}</td>
									</tr>
								))}
							</> : <NoDataMsg columnsCount={3} msg={noDataMsg} />}
						</tbody>
					</table>
				</CardBody>
				<PaginationButtons
					data={items}
					label='items'
					setCurrentPage={setCurrentPage}
					currentPage={currentPage}
					perPage={perPage}
					setPerPage={setPerPage}
				/>
			</Card>

			<Modal
				setIsOpen={setIsModalOpen}
				isOpen={isModalOpen}
				titleId='attendanceNotSubmittedDepartments'
				isStaticBackdrop
				isScrollable
				size='lg'>
				<ModalHeader
					className="'modal-header '"
					setIsOpen={() => {
						setIsModalOpen(false);
					}}
					onClick={() => setIsModalOpen(false)}>
					<ModalTitle id='attendanceNotSubmittedDepartments'>
						Attendance Not Submitted Standard List
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='col-lg-5'></div>
					<div className='col-lg-12'>
						<table className='table table-modern table-hover '>
							<thead >
								<tr>
									<th className={classNames('bg-l25-info fs-6', { 'bg-l10-info': !darkModeStatus, 'bg-lo25-info': darkModeStatus })}>
										STANDARD
									</th>
									<th className={classNames('bg-l25-info fs-6', { 'bg-l10-info': !darkModeStatus, 'bg-lo25-info': darkModeStatus })}>
										SECTION
									</th>
								</tr>
							</thead>
							<tbody>
								{attendanceData != '' ?
									<>
										{attendanceData.map((i: any, index: any) => (
											<tr key={index}>
												<td>{i.standardName}</td>
												<td>{i.sectionName}</td>
											</tr>
										))}
									</> : <NoDataMsg columnsCount={3} msg={noDataMsg} />}
							</tbody>
						</table>
					</div>
				</ModalBody>
			</Modal>
			<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
		</>
	);
};

export default AttendanceNotSubmitted;
