import { useFormik } from "formik";
import { useEffect, useState, useRef, useContext } from "react";
import useDarkMode from "../../../hooks/useDarkMode";
import useSortableData from "../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import { toasts } from "../../../services/toast.service";
import { useReactToPrint } from "react-to-print";
import Page from "../../../layout/Page/Page";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import Icon from "../../icon/Icon";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../bootstrap/Dropdown";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../services/ExportService";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../bootstrap/Modal";
import Input from "../../bootstrap/forms/Input";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Collapse from "../../bootstrap/Collapse";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../bootstrap/OffCanvas";
import AlertService from "../../../services/AlertService";
import OpenCardComponent from "../../../common/components/OpenCardComponent";
import AuthContext from "../../../contexts/authContext";
import { getColumnsForDataTable, getGraduationType, getStandardDetails, onlyAllowNumber, updateColumnsForDataTable, updateFilter } from "../../../services/common.service";
import { getLicenseKey } from "../../../services/application.settings";
import { TableLoader, showLoader } from "../../../services/loader.services";
import NoDataMsg from "../../../common/components/NoDataMsg";
import SearchableSelect from "../../../common/components/SearchableSelect";
import { getBatchMasterDetails, getSectionDetails } from "../../../services/master.service";
import { addClassCapacity, getClassCapacityByBatchMasterId, getClassListByBatchMasterId, updateClassCapacity } from "../../../services/class.service";

function ClassCapacity() {

    useEffect(() => {
        getClassCapacityByBatchMaster(0)
        getBatchMasterList();
        getColumnsForTable('getClassCapacityByBatchMasterId', 'get');
        getStandardList()
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [batchMasterId, setBatchMasterId] = useState<any>('');
    const [batchName, setBatchName] = useState<any>('');
    const [standard, setStandard] = useState('');
    const [graduationTypeId, setGraduationTypeId] = useState<any>(Number)
    const [batchByCourseMasterData, setBatchByCourseMasterData] = useState<any>([])
    const [classCapacityData, setClassCapacityData] = useState([]);
    const [classByBatchMasterData, setClassByBatchMasterData] = useState([]);

    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const [classCapacity, setClassCapacity] = useState<any>([])
    const [dataStatus, setDataStatus] = useState(false)

    // ForModal And Tables States
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(classCapacityData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [dataSuccess, setDataSuccess] = useState(false)
    const [isOpenListCard, setIsOpenListCard] = useState(false)
    const [addCourseCapacityModal, setAddCourseCapacityModal] = useState(false)
    const [editCourseCapacityOffcanvas, setEditCourseCapacityOffcanvas] = useState(false);
    const [isLoader, setIsLoader] = useState(false)

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [noDataMsg, setNoDataMsg] = useState('')

    const courseCapacityCollapseForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            graduationTypeId: '',
            batchMasterId: '',
        },
        validate: (values) => {
            const errors: {
                graduationTypeId?: string;
                batchMasterId?: string;
            } = {};
            if (!graduationTypeId) {
                errors.graduationTypeId = 'Required';
            }
            if (!batchMasterId) {
                errors.batchMasterId = 'Required';
            }
            return errors;
        },

        //validateOnChange: false,
        onSubmit: () => { viewCourseCapacity() },
    });

    const classCapacityEditForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            classCapacityId: '',
            govQuota: '',
            managementQuota: '',
            totalAllocated: 0,
            standardDetailsId: '',
            batchMasterId: '',
        },
        validate: (values: any) => {
            const errors: {
                govQuota?: string;
                managementQuota?: string;
            } = {};
            if (!values.govQuota) {
                errors.govQuota = 'Required';
            }
            if (!values.managementQuota) {
                errors.managementQuota = 'Required';
            }
            
            return errors;
        },
        //validateOnChange: false,
        onSubmit: (values) => { updateClassCapacitySubmit() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    const selectBatch = (e: any) => {
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        setBatchName(batchMasterId?.label)
    }

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    const viewCourseCapacity = () => {
        setAddCourseCapacityModal(true)        
        getClassListByBatchMaster(batchMasterId?.value)
    }

    function onActivate(i: any) {
        if (i != undefined) {

            let selectedCourseCapacity = i
            setEditCourseCapacityOffcanvas(true)

            classCapacityEditForm.setValues({
                classCapacityId: selectedCourseCapacity.classCapacityId,
                govQuota: selectedCourseCapacity.govQuota,
                managementQuota: selectedCourseCapacity.managementQuota,
                totalAllocated: Number(selectedCourseCapacity.govQuota) + Number(selectedCourseCapacity.managementQuota),
                standardDetailsId: selectedCourseCapacity.standardDetailsId,
                batchMasterId: selectedCourseCapacity.batchMasterId,
            })

            setBatchName(selectedCourseCapacity.batchName)
            setStandard(selectedCourseCapacity.standardName)
        }
    }

    function getBatchMasterList() {
        getBatchMasterDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchMasterDetails;
                    if (data != undefined) {
                        setBatchByCourseMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBatchByCourseMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const [standardDetailsData, setStandardDetailsData] = useState<any>([])

    function getStandardList() {
        getStandardDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.standardDetails;
                    if (data != undefined) {
                        setStandardDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStandardDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getClassListByBatchMaster(batchMasterId: any) {
        getClassListByBatchMasterId(batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.classListByBatchMasterId[0].capacityDetails;
                    let batchName = response.data.data.classListByBatchMasterId[0].batchName;
                    if (data != undefined) {
                        setIsLoader(false);
                        setClassByBatchMasterData(data);
                        setBatchName(batchName);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getClassCapacityByBatchMaster(batchMasterId: any) {
        setDataStatus(true)
        getClassCapacityByBatchMasterId(batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.classCapacityByBatchMasterId;
                    if (data != undefined) {
                        getColumnsForTable('getClassCapacityByBatchMasterId', 'get');
                        setClassCapacityData(data);
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        setDataStatus(true)
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true)
                    //toasts(response.data.message, "Error")
                    getColumnsForTable('getClassCapacityByBatchMasterId', 'get');
                    setClassCapacityData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setDataStatus(true)
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function updateGovQuotaValue(event: any, cell: number, managementQuota: number) {

        const newData: any = [];

        classByBatchMasterData.forEach((item: any, index: any) => {
            if (item['standardDetailsId'] == cell) {
                item['govQuota'] = event.target.value
                item['totalAllocated'] = Number(item['govQuota']) + Number(item['managementQuota'])
            }
            newData.push(item)
        });

        setClassByBatchMasterData(newData)

        if (classCapacity.length == 0) {
            var obj
            obj = {
                standardDetailsId: cell,
                govQuota: event.target.value,
                managementQuota: managementQuota > 0 ? managementQuota : "",
                totalAllocated: event.target.value + managementQuota,
            };
            classCapacity.push(obj);
        } else {
            let selectedItem: any
            classCapacity.forEach((item: any, index: number) => {
                if (item['standardDetailsId'] == cell) {
                    selectedItem = item
                }
            });
            if (selectedItem != undefined) {
                selectedItem['govQuota'] = event.target.value
                selectedItem['totalAllocated'] = Number(selectedItem['govQuota']) + Number(selectedItem['managementQuota'])
            } else {
                var obj
                obj = {
                    standardDetailsId: cell,
                    govQuota: event.target.value,
                    managementQuota: managementQuota > 0 ? managementQuota : "",
                    totalAllocated: event.target.value + managementQuota,
                };
                classCapacity.push(obj);
            }
        }        
    }

    function updateManagementQuotaValue(event: any, cell: number, govQuota: number) {

        const newData: any = [];

        classByBatchMasterData.forEach((item: any, index: any) => {
            if (item['standardDetailsId'] == cell) {
                item['managementQuota'] = event.target.value
                item['totalAllocated'] = Number(item['govQuota']) + Number(item['managementQuota'])
            }

            newData.push(item)
        });

        setClassByBatchMasterData(newData)

        if (classCapacity.length == 0) {
            var obj
            obj = {
                standardDetailsId: cell,
                govQuota: govQuota > 0 ? govQuota : "",
                managementQuota: event.target.value,
                totalAllocated: event.target.value + govQuota,

            };
            classCapacity.push(obj);
        } else {
            let selectedItem: any
            classCapacity.forEach((item: any, index: number) => {
                if (item['standardDetailsId'] == cell) {
                    selectedItem = item
                }
            });
            if (selectedItem != undefined) {
                selectedItem['managementQuota'] = event.target.value
                selectedItem['totalAllocated'] = Number(selectedItem['govQuota']) + Number(selectedItem['managementQuota'])
            } else {
                var obj
                obj = {
                    standardDetailsId: cell,
                    govQuota: govQuota > 0 ? govQuota : "",
                    managementQuota: event.target.value,
                    totalAllocated: event.target.value + govQuota,
                };
                classCapacity.push(obj);
            }
        }
    }

    function setClassCapacityDetails() {
        return {
            batchMasterId: batchMasterId?.value,
            classCapacity: classCapacity,
            licenseKey: getLicenseKey,
            staffDetailsId: userAccountId,
        }
    }

    function setClassCapacityEditDetails() {
        return ({
            classCapacityId: classCapacityEditForm.values.classCapacityId,
            batchMasterId: classCapacityEditForm.values.batchMasterId,
            standardDetailsId: classCapacityEditForm.values.standardDetailsId,
            govQuota: classCapacityEditForm.values.govQuota,
            managementQuota: classCapacityEditForm.values.managementQuota,
            totalAllocated: Number(classCapacityEditForm.values.govQuota) + Number(classCapacityEditForm.values.managementQuota),
            licenseKey: getLicenseKey,
            staffDetailsId: userAccountId,
        })
    }

    function addClassCapacitySubmit() {
        showLoader(true)
        if (classCapacity.length > 0) {
            let classCapacityPostData = setClassCapacityDetails()
            addClassCapacity(classCapacityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getClassCapacityByBatchMaster(0);
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "warning" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                }, (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )

        } else {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function updateClassCapacitySubmit() {
        showLoader(true)
        if (classCapacityEditForm.isValid) {
            let classCapacityEditPostData = setClassCapacityEditDetails()
            updateClassCapacity(classCapacityEditPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getClassCapacityByBatchMaster(0);
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "warning" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                }, (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (classCapacityEditForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getClassCapacityByBatchMasterId', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeAndReset() {
        setAddCourseCapacityModal(false)
        setEditCourseCapacityOffcanvas(false)
        classCapacityEditForm.resetForm();
        setClassCapacity([])
        setGraduationTypeId('');
        setBatchMasterId('');
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <PageWrapper title="Class Capacity">
                <Page container='fluid'>
                    <Collapse isOpen={isOpenListCard}>
                        <Card stretch data-tour='list'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>
                                        Class Capacity Information
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <form noValidate onSubmit={courseCapacityCollapseForm.handleSubmit}>
                                    <div className="row g-4">
                                        <div className='col-md-3'>
                                            <FormGroup id='batchMasterId' label='Batch' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Batch Type' placeholder="Select Batch Type" onChange={(e: any) => selectBatch(e)} value={batchMasterId} isValid={courseCapacityCollapseForm.isValid} isTouched={courseCapacityCollapseForm.touched.batchMasterId} onBlur={courseCapacityCollapseForm.handleBlur} invalidFeedback={courseCapacityCollapseForm.errors.batchMasterId} list={batchByCourseMasterData.map((data: any) => ({ value: data.batchMasterId, label: data.batchName }))} />
                                            </FormGroup>
                                        </div>
                                        {batchMasterId?.value != undefined ?
                                            <div className="col-md-3">
                                                <Button className="mt-2" onClick={viewCourseCapacity} icon='ArrowDownward' color='primary' type="submit">View</Button>
                                            </div> : null
                                        }
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </Collapse>

                    <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} />

                    <Card stretch data-tour='list' ref={componentRef}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                <CardTitle tag='div' className='h5'>Class Capacity Information</CardTitle>
                            </CardLabel>&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getClassCapacityByBatchMasterId', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("ClassCapacity", classCapacityData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(classCapacityData, columnVisibilityData, "ClassCapacity")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("ClassCapacity", classCapacityData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Button color='primary' icon='Add' isLight onClick={() => setIsOpenListCard(!isOpenListCard)}>
                                    Add New
                                </Button>
                            </CardActions>
                        </CardHeader>

                        <CardBody className='table-responsive' isScrollable>
                            {!dataStatus ?
                                <TableLoader /> :
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                            <th scope='col' className='d-print-none'>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ?
                                            <>
                                                {filteredData.map((rowData: any) => (
                                                    <tr key={rowData.classCapacityId}>
                                                        <td>{rowData.sno}</td>
                                                        {columnVisibilityData.map((column: any) => (
                                                            (column.isDisplay && rowData[column.keyName]) ?
                                                                <td key={`${rowData.classCapacityId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                        ))}
                                                        <td className="d-print-none" >
                                                            <Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} /></td>
                                                    </tr>
                                                ))
                                                }</>
                                            :
                                            <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                    </tbody>
                                </table>
                            }
                        </CardBody>
                        <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                    </Card>
                </Page>
            </PageWrapper>

            <Modal setIsOpen={setAddCourseCapacityModal} isOpen={addCourseCapacityModal} titleId='addCourseCapacity' isStaticBackdrop isScrollable size='lg'>
                <ModalHeader className="'modal-header'" setIsOpen={() => { setAddCourseCapacityModal(false) }} onClick={closeAndReset}>
                    <ModalTitle id='addCourseCapacity'>Add Class Capacity</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <div className='p-3 mb-2 bg-primary text-white fw-bold'>Batch : {batchName}</div>
                    <table className='table table-modern mt-3'>
                        <thead>
                            <tr className="text-center">
                                <th style={{ width: '15%' }} scope="col">Standard</th>
                                <th style={{ width: '20%' }} scope="col">Merit</th>
                                <th style={{ width: '20%' }} scope="col">Self-Supported</th>
                                <th style={{ width: '20%' }} scope="col">Total</th>
                            </tr>
                        </thead>
                        <tbody className='justify-content-center'>
                            {classByBatchMasterData.map((i: any) => (
                                <tr className="text-center" key={i.batchMasterId}>
                                    <td scope='col'>{i.standardName}</td>
                                    <td scope='col'>
                                        <FormGroup id='uniRegNo'>
                                            <Input className="w-60" placeholder="" value={i.govQuota ? i.govQuota : ''} onChange={(event) => updateGovQuotaValue(event, i.standardDetailsId, i.managementQuota ? i.managementQuota : 0)} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                        </FormGroup>
                                    </td>
                                    <td scope='col'>
                                        <FormGroup id='uniRegNo'>
                                            <Input className="w-60" placeholder="" value={i.managementQuota ? i.managementQuota : ''} onChange={(event) => updateManagementQuotaValue(event, i.standardDetailsId, i.govQuota ? i.govQuota : 0)} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                        </FormGroup>
                                    </td>
                                    <td scope='col'>
                                        <FormGroup id='uniRegNo'>
                                            <Input className="w-60" placeholder="" value={i.totalAllocated ? i.totalAllocated : ''} disabled onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                        </FormGroup>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </ModalBody>

                <ModalFooter>
                    <Button color='info' isOutline className='border-0' onClick={closeAndReset}>Close</Button>
                    <Button color='info' icon='Save' type="submit" onClick={addClassCapacitySubmit} isDisable={classCapacity.length > 0 ? false : true}>Save </Button>
                </ModalFooter>
            </Modal>

            <OffCanvas setOpen={setEditCourseCapacityOffcanvas} isOpen={editCourseCapacityOffcanvas} titleId='editCourseCapacityOffcanvas' isBodyScroll placement='end' tag="form" noValidate onSubmit={classCapacityEditForm.handleSubmit}>
                <OffCanvasHeader setOpen={setEditCourseCapacityOffcanvas} onClick={closeAndReset}>
                    <OffCanvasTitle id='editCourseCapacityOffcanvas'>Update Class Capacity</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className="row g-4">
                        <div className='col-12'>
                            <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                }-info bg-l${darkModeStatus ? 'o50' : '10'
                                }-info-hover transition-base rounded-2 mb-4`}
                                shadow='sm'>
                                <CardBody>
                                    <div className='d-flex align-items-center pb-3'>
                                        <div className='flex-shrink-0 ms-2'>
                                            <Icon icon='Assignment' size='4x' color='info' />
                                        </div>
                                        <div className="row">
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-5 text-dark mb-0'>
                                                    {batchName}
                                                </div>
                                                <div className='text-muted'>
                                                    Batch
                                                </div>
                                            </div>
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-5 text-dark mb-0'>
                                                    {standard}
                                                </div>
                                                <div className='text-muted'>
                                                    Standard
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='govQuota' label='Merit'>
                                <Input onChange={classCapacityEditForm.handleChange} value={classCapacityEditForm.values.govQuota} isValid={classCapacityEditForm.isValid} onBlur={classCapacityEditForm.handleBlur} isTouched={classCapacityEditForm.touched.govQuota} invalidFeedback={classCapacityEditForm.errors.govQuota} type='text' placeholder="Merit" required onKeyDown={(e: any) => onlyAllowNumber(e)} />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='managementQuota' label='Self-Supported'>
                                <Input onChange={classCapacityEditForm.handleChange} value={classCapacityEditForm.values.managementQuota} isValid={classCapacityEditForm.isValid} onBlur={classCapacityEditForm.handleBlur} isTouched={classCapacityEditForm.touched.managementQuota} invalidFeedback={classCapacityEditForm.errors.managementQuota} type='text' placeholder="Self-Supported" required onKeyDown={(e: any) => onlyAllowNumber(e)} />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='totalAllocated' label='Total Allocated'>
                                <Input onChange={classCapacityEditForm.handleChange} value={Number(classCapacityEditForm.values.govQuota) + Number(classCapacityEditForm.values.managementQuota)} isValid={classCapacityEditForm.isValid} onBlur={classCapacityEditForm.handleBlur} isTouched={classCapacityEditForm.touched.totalAllocated} invalidFeedback={classCapacityEditForm.errors.totalAllocated} type='text' placeholder="Total Allocated" required onKeyDown={(e: any) => onlyAllowNumber(e)} disabled />
                            </FormGroup>
                        </div>
                    </div>
                </OffCanvasBody>
                <div className='row m-0'>
                    {/* <div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
                        <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteCourseSubmit} />
                    </div> */}
                    {/* <div className='col-6 p-3'>
                        <Button color='danger' className='w-100' icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                            Delete
                        </Button>
                    </div> */}
                    <div className='col-12 p-3'>
                        <Button color='info' icon="Save" className='w-100' type="submit" isDisable={!classCapacityEditForm.isValid && !!classCapacityEditForm.submitCount}> Update</Button>
                    </div>
                </div>
            </OffCanvas>
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </>
    )
}
export default ClassCapacity;