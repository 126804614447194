import { useContext, useEffect, useRef, useState } from "react";

import { useFormik } from "formik";
import { useReactToPrint } from "react-to-print";
import NoDataMsg from "../../../common/components/NoDataMsg";
import AuthContext from "../../../contexts/authContext";
import useDarkMode from "../../../hooks/useDarkMode";
import useSortableData from "../../../hooks/useSortableData";
import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import AlertService from "../../../services/AlertService";
import { getLicenseKey } from "../../../services/application.settings";
import { updateFilter, getColumnsForDataTable, updateColumnsForDataTable, getCurrentDateAndTime } from "../../../services/common.service";
import { downloadExcel, convertJsonToPdf, downloadFile } from "../../../services/ExportService";
import { TableLoader } from "../../../services/loader.services";
import { getCcAvenueReport } from "../../../services/report.service";
import { toasts } from "../../../services/toast.service";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import Card, { CardHeader, CardLabel, CardTitle, CardActions, CardBody } from "../../bootstrap/Card";
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from "../../bootstrap/Dropdown";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import FormGroup from "../../bootstrap/forms/FormGroup";import { dataPagination } from "../../PaginationButtons";
import Input from "../../bootstrap/forms/Input";
import Icon from "../../icon/Icon";

function CcAvenueReport() {
	const { userAccountId, userTypeId } = useContext(AuthContext);


	useEffect(() => {
		getCcAvenueReportList(userAccountId)
	}, [])

	const { themeStatus, darkModeStatus } = useDarkMode();
	const [ccAvenueReportsData, setCcAvenueReportData] = useState([]);
	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [allColumnsData, setAllColumnsData] = useState<any>([]);
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [columnDataById, setColumnDataById] = useState([])
	const componentRef = useRef(null);
	const [isChecked, setIsChecked] = useState(false)
	const [dataStatus, setDataStatus] = useState(false)

	// ForModal
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(ccAvenueReportsData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);

	// For Alert
	const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
	const [isOpen, setIsOpen] = useState(false);

	const [noDataMsg, setNoDataMsg] = useState('')

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },
		//validateOnChange: false,
		onSubmit: () => { },
	});

	// Filter
	const filteredData = updateFilter(ccAvenueReportsData, columnVisibilityData, columnVisibilityForm);

	function getCcAvenueReportList(userAccountId: any) {
		setDataStatus(false)
		getCcAvenueReport(userAccountId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.ccAvenueReport;
					if (data != undefined) {
						getColumnsForTable('getCcAvenueReport', 'get');
						setCcAvenueReportData(data);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
						setDataStatus(true)
					} else {
						setDataStatus(true)
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					setDataStatus(true)
					getColumnsForTable('getCcAvenueReport', 'get');
					setCcAvenueReportData([]);
					setNoDataMsg(response.data.message)
					// toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setDataStatus(true)
					toasts(errorDescription, "Error")
				}
			}, error => {
				setDataStatus(true)
				toasts(error, "Error")
			}
		)
	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(userTypeId, apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData)
						let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
						setColumnVisibilityData(columnVisibilityData)

						if (type == 'get') {
							const allData = allColumnsData.map((item: any) => item.isDisplay);
							const allColumns = allData.filter((item: any) => item == false);
							setIsChecked(allColumns == '' ? true : false);
						}
						else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

							for (let i = 0; i < nonDefaultValue.length; i++) {

								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
								}
								all.push(obj);
							}
							updateColumnsSubmit('', '', all)
						}
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)

	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

		if (columnVisibilityForm.isValid) {

			setColumnDataById(isDisplay)
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type ? type : [
					{
						columnVisibilityId: columnVisibilityId,
						isDisplay: isDisplay ? 0 : 1
					}
				]
			}

			updateColumnsForDataTable(columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getCcAvenueReport', 'get');
					}
					else if (data.success == false) {
						// toasts(data.success, "Error")
					}
					else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, "Error")
					}
				}
				, (error) => {
					toasts(error, "Error")
				}
			)
		} else if (columnVisibilityForm.isValid == false) {
			toasts("Please fill all the details!", "Error")
		}
	}

	// Print
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<>
			<PageWrapper title={`CcAvenue Report`}  ref={componentRef} >
				<Page container='fluid'>
					<Card stretch data-tour='list'>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info' className="col-lg-6">
								<CardTitle tag='div' className='h5'>CcAvenue Report List</CardTitle>
							</CardLabel>&nbsp;&nbsp;

							<ButtonGroup className="col-lg-3 d-print-none" color='primary'>
								<Icon className="mt-1" icon='Search' size='2x' color='primary' />
								<Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
							</ButtonGroup>

							<CardActions className="d-print-none">
								<Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
									<DropdownToggle>
										<Button icon='FilterAlt' color='primary' isLight>Filter</Button>
									</DropdownToggle>
									<DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
										<DropdownItem>
											<div className='container py-2'>
												<form className='row g-3'>
													<div className='col-12'>
														<FormGroup>
															<h6>Select All</h6>
															<Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getCcAvenueReport', 'post')} checked={isChecked} ariaLabel='Available status' />
														</FormGroup>
													</div>
													<div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
														<FormGroup>
															<h6>Columns</h6>
															<ChecksGroup>
																{allColumnsData.map((i: any) => (
																	<Checks key={i.columnVisibilityId} label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
																))}
															</ChecksGroup>
														</FormGroup>
													</div>
												</form>
											</div>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
								<Dropdown isButtonGroup>
									<DropdownToggle>
										<Button color='primary' isLight icon='CloudDownload'> Export </Button>
									</DropdownToggle>
									<DropdownMenu isAlignmentEnd>
										<DropdownItem>
											<Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("ccAvenueReport", ccAvenueReportsData, columnVisibilityData)}> Excel </Button>
										</DropdownItem>
										<DropdownItem>
											<Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(ccAvenueReportsData, columnVisibilityData, "ccAvenueReport")}> PDF
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("ccAvenueReport", ccAvenueReportsData, columnVisibilityData)}> CSV </Button>
										</DropdownItem>
										<DropdownItem>
											<Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
							</CardActions>
						</CardHeader>

						<CardBody className='table-responsive'>
							{!dataStatus ?
								<TableLoader /> :
								<>
									<table className='table table-modern table-hover text-nowrap'>
										<thead>
											<tr>
												<th scope='col' onClick={() => requestSort('sno')}
													className='cursor-pointer text-decoration-underline'>S.No{' '}
													<Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
												</th>
												{columnVisibilityData.map((column: any) => (
													<th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
														className='cursor-pointer text-decoration-underline'>
														{column.columnName}
														<Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
													</th>
												))}
											</tr>
										</thead>
										<tbody>{filteredData != "" ? <>{filteredData.map((rowData: any) => (<tr key={rowData.studentDetailsId}><td>{rowData.sno}</td>{columnVisibilityData.map((column: any) => ((column.isDisplay && rowData[column.keyName]) ? <td key={`${rowData.studentDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}</tr>
										))}<tr className='table-primary'>
												<td colSpan={columnVisibilityData.length}><strong >TOTAL :</strong></td>
												<td><strong>{ccAvenueReportsData.length > 0 ? ccAvenueReportsData.map((data: any) => data.amount).reduce((a: any, b: any) => a + b) : 0}</strong></td>
											</tr></> : <NoDataMsg columnsCount={columnVisibilityData.length + 1} msg={noDataMsg} />}</tbody></table>
									<h6 className='mg-lg-t-10'>
										Date : {getCurrentDateAndTime('dateAndTime')}
									</h6>
								</>

							}
						</CardBody>
					</Card>
				</Page>

				<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
			</PageWrapper>
		</>
	)
}
export default CcAvenueReport;