import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getClassCapacityByBatchMasterId = (batchMasterId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getClassCapacityByBatchMasterId/${getLicenseKey}/${batchMasterId}`,
        response => {
            success(response)
        },
        error => {
            failure("getClassCapacityByBatchMasterId - " + error.message)
            console.log('error caught in service : getClassCapacityByBatchMasterId')
        },
    );

export const addClassCapacity = (classCapacityPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addClassCapacity`, classCapacityPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addClassCapacity')
        }
    );

export const updateClassCapacity = (classCapacityPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateClassCapacity`, classCapacityPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateClassCapacity')
        }
    );

export const deleteAdmissionFormFee = (admissionFeeMasterPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteAdmissionFormFee`, admissionFeeMasterPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteAdmissionFormFee')
        }
    ); 

export const getClassListByBatchMasterId = (batchMasterId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getClassListByBatchMasterId/${getLicenseKey}/${batchMasterId}`,
        response => {
            success(response)
        },
        error => {
            failure("getClassListByBatchMasterId - " + error.message)
            console.log('error caught in service : getClassListByBatchMasterId')
        },
    );