import React, { lazy, useContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthContext from '../../contexts/authContext';
import Login from '../../pages/presentation/auth/Login';
import { getAllScreensByUserAccountId } from '../../services/screen.service';
import contentRoutes from '../../routes/contentRoutes';
import AluminiLogin from '../../pages/presentation/auth/AluminiLogin';
import DashboardPage from '../../pages/presentation/dashboard/DashboardPage';

const PAGE_404 = lazy(() => import('../../pages/presentation/auth/Page404'));
const UnauthorizedPage = lazy(() => import('../../pages/presentation/auth/UnauthorizedPage'));

const ContentRoutes = () => {

	const [getMenu, setGetMenu] = useState<any>([]);
	const { userAccountId, userTypeId } = useContext(AuthContext);

	useEffect(() => {
		if (userAccountId != "") {
			getScreensByUserAccountId(userAccountId, userTypeId);
		}
	}, []);

	function getScreensByUserAccountId(userAccountId: number, userTypeId: number) {

		getAllScreensByUserAccountId(userAccountId, userTypeId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.allScreens;
					if (data != undefined) {

						if (userTypeId == 9) {
							generateMenuFinal2(data)
						}
						else {
							generateMenu(data);
						}

					} else {
						// toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					// toasts(errorDescription, "Error")
				}
			}, error => {
				// toasts(error, "Error")
			}
		)
	}

	function generateMenu(screenData: any) {

		let menu: any = [];

		for (let i = 0; i < screenData.length; i++) {
			let screenGroupMain = screenData[i]

			for (let j = 0; j < screenGroupMain.screenGroup.length; j++) {
				let screenGroup = screenGroupMain.screenGroup[j]

				for (let k = 0; k < screenGroup.screens.length; k++) {
					let screen = screenGroup.screens[k]

					for (let l = 0; l < contentRoutes().contentsMenu.length; l++) {

						let page = contentRoutes().contentsMenu[l]

						if (screen.screenPath == page.path) {
							menu.push({
								path: screen.screenPath,
								element: page.element,
							})
						}
					}
				}
			}
		}
		menu = menu

		const screen = [...contentRoutes().commonContents, ...menu]
		setGetMenu(screen)
	}

	function generateMenuFinal2(screenData: any) {

		let menu: any = [];

		for (let i = 0; i < screenData.length; i++) {
			let screenGroup = screenData[i]

			for (let l = 0; l < contentRoutes().contentsMenu.length; l++) {

				let page = contentRoutes().contentsMenu[l]

				if (screenGroup.screenPath == page.path) {
					menu.push({
						path: screenGroup.screenPath,
						element: page.element,
					})
				}
			}
		}
		menu = menu

		const screen = [...contentRoutes().commonContents, ...menu]
		setGetMenu(screen)
	}

	const screen: any = [...getMenu]
	
	return (
		<Routes>
			{userAccountId != "" ?
				<>

					{screen != "" ?
						<>
							{screen.map((page: any) => (
								// eslint-disable-next-line react/jsx-props-no-spreading
								<Route key={page.path} {...page} />
							))}
						</>
						:
						<>
							{contentRoutes().contentsMenu.map((page: any) => (
								// eslint-disable-next-line react/jsx-props-no-spreading
								<Route key={page.path} {...page} />
							))}
						</>
					}
				</>
				:
				<>
					<Route path='/login' element={<Login />} />
					<Route path='/aluminiLogin' element={<AluminiLogin />} />
				</>
			}
			{/* <Route path='*' element={<PAGE_404 />} /> */}
			<Route path='*' element={<UnauthorizedPage />} />
			<Route path='/' element={<DashboardPage />} />
		</Routes>
	);
};

export default ContentRoutes;
