import { useContext, useEffect, useRef, useState } from "react";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import Page from "../../../layout/Page/Page";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Input from "../../bootstrap/forms/Input";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import useSortableData from "../../../hooks/useSortableData";
import { useFormik } from "formik";
import dayjs from "dayjs";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../bootstrap/Dropdown";
import { convertJsonToPdf, downloadExcel, downloadFile, printPage } from "../../../services/ExportService";
import useDarkMode from "../../../hooks/useDarkMode";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import { useReactToPrint } from "react-to-print";
import Icon from "../../icon/Icon";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../bootstrap/OffCanvas";
import AlertService from "../../../services/AlertService";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import DeleteComponents from "../../../common/components/DeleteComponents";
import { addAcademicPeriod, deleteAcademicPeriod, getAcademicPeriod, updateAcademicPeriod } from "../../../services/master.service";
import { getLicenseKey } from "../../../services/application.settings";
import AuthContext from "../../../contexts/authContext";
import { getColumnsForDataTable, updateColumnsForDataTable, updateFilter } from "../../../services/common.service";
import { TableLoader, showLoader } from "../../../services/loader.services";
import NoDataMsg from "../../../common/components/NoDataMsg";
import { toasts } from "../../../services/toast.service";

function AcademicPeriod() {

    useEffect(() => {
        getAcademicPeriodList()
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [academicPeriodData, setAcademicPeriodData] = useState([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState<any>([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const [dataStatus, setDataStatus] = useState(false)

    // ForModal
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(academicPeriodData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [addAcademicPeriodOffcanvas, setAddAcademicPeriodOffcanvas] = useState(false);
    const [updateAcademicPeriodOffcanvas, setUpdateAcademicPeriodOffcanvas] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [noDataMsg, setNoDataMsg] = useState('')

    const academicPeriodForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            periodFrom: "",
            periodTo: '',
            period: '',
        },
        validate: (values) => {
            const errors: {
                periodFrom?: string;
                periodTo?: string;
            } = {};
            if (!values.periodFrom) {
                errors.periodFrom = 'Required';
            }
            if (!values.periodTo) {
                errors.periodTo = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { academicPeriodSubmit() },
    });

    const academicPeriodEditForm = useFormik({
        enableReinitialize: true,
        initialValues: {

            periodFrom: '',
            periodTo: '',
            period: '',
            academicPeriodId: '',
        },
        validate: (values) => {
            const errors: {
                periodFrom?: string;
                periodTo?: string;
                period?: string;
                academicPeriodId?: string;
            } = {};
            if (!values.periodFrom) {
                errors.periodFrom = 'Required';
            }
            if (!values.periodTo) {
                errors.periodTo = 'Required';
            }
            if (!values.period) {
                errors.period = 'Required';
            }
            if (!values.academicPeriodId) {
                errors.academicPeriodId = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { updateAcademicPeriodSubmit() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);


    function onActivate(i: any) {
        if (i != undefined) {

            let selectedAcademic = i
            setUpdateAcademicPeriodOffcanvas(true)

            academicPeriodEditForm.setValues({
                periodFrom: selectedAcademic.periodFrom,
                periodTo: selectedAcademic.periodTo,
                period: selectedAcademic.period,
                academicPeriodId: selectedAcademic.academicPeriodId,
            });
        }
    }

    function getAcademicPeriodList() {
        setDataStatus(false)
        getAcademicPeriod(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.academicPeriod;
                    if (data != undefined) {
                        getColumnsForTable('getAcademicPeriod', 'get');
                        setAcademicPeriodData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        setDataStatus(true)
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true)
                    getColumnsForTable('getAcademicPeriod', 'get');
                    setAcademicPeriodData([]);
                    setNoDataMsg(response.data.message)
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setDataStatus(true)
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function setAcademicPeriodDetails() {

        return ({
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
            periodFrom: academicPeriodForm.values.periodFrom,
            periodTo: academicPeriodForm.values.periodTo,
            period: dayjs(academicPeriodForm.values.periodFrom).format('YYYY') + '-' + dayjs(academicPeriodForm.values.periodTo).format('YYYY'),
        })
    }

    function setEditAcademicPeriodDetails() {
        return ({
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
            academicPeriodId: academicPeriodEditForm.values.academicPeriodId,
            periodFrom: academicPeriodEditForm.values.periodFrom,
            periodTo: academicPeriodEditForm.values.periodTo,
            period: dayjs(academicPeriodEditForm.values.periodFrom).format('YYYY') + '-' + dayjs(academicPeriodEditForm.values.periodTo).format('YYYY'),
        })
    }

    function academicPeriodSubmit() {
        showLoader(true)
        if (academicPeriodForm.isValid) {
            let academicPeriodPostData = setAcademicPeriodDetails()
            addAcademicPeriod(academicPeriodPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getAcademicPeriodList();
                        closeAndReset();

                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (academicPeriodForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function updateAcademicPeriodSubmit() {
        showLoader(true)
        if (academicPeriodEditForm.isValid) {
            let academicPeriodPostData = setEditAcademicPeriodDetails()
            updateAcademicPeriod(academicPeriodPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getAcademicPeriodList();
                        closeAndReset();

                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (academicPeriodEditForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteAcademicPeriodSubmit() {
        showLoader(true)
        if (academicPeriodEditForm.isValid) {
            let academicPeriodPostData = setEditAcademicPeriodDetails()
            deleteAcademicPeriod(academicPeriodPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getAcademicPeriodList();
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (academicPeriodEditForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getAcademicPeriod', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeAndReset() {
        academicPeriodForm.resetForm();
        academicPeriodEditForm.resetForm();
        setIsDeleteOpen(false);
        setAddAcademicPeriodOffcanvas(false)
        setUpdateAcademicPeriodOffcanvas(false)
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <PageWrapper title={`Academic Period`}>
                <Page container='fluid'>
                    <Card stretch data-tour='list' ref={componentRef}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                <CardTitle tag='div' className='h5'>Academic Period</CardTitle>
                            </CardLabel>&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getAcademicPeriod', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("Academic Period", academicPeriodData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(academicPeriodData, columnVisibilityData, "Academic Period")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("Academic Period", academicPeriodData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Button color='primary' icon='Add' isLight onClick={() => setAddAcademicPeriodOffcanvas(true)}>
                                    Add New
                                </Button>
                            </CardActions>
                        </CardHeader>

                        <CardBody className='table-responsive' isScrollable>
                            {!dataStatus ?
                                <TableLoader /> :
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                            <th scope='col' className='d-print-none'>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>{filteredData != "" ? <>{filteredData.map((rowData: any) => (<tr key={rowData.academicPeriodId}><td>{rowData.sno}</td>{columnVisibilityData.map((column: any) => ((column.isDisplay && rowData[column.keyName]) ? <td key={`${rowData.academicPeriodId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}<td className="d-print-none"><Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} /></td></tr>))}</> : <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}</tbody>
                                </table>
                            }
                        </CardBody>

                        <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                    </Card>
                </Page>

                <OffCanvas setOpen={setAddAcademicPeriodOffcanvas} isOpen={addAcademicPeriodOffcanvas} titleId='addAcademicPeriodOffcanvas' isBodyScroll placement='end'>
                    <OffCanvasHeader setOpen={setAddAcademicPeriodOffcanvas} onClick={closeAndReset}>
                        <OffCanvasTitle id='addAcademicPeriodOffcanvas'>Academic Period Creation</OffCanvasTitle>
                    </OffCanvasHeader>
                    <form noValidate onSubmit={academicPeriodForm.handleSubmit}>
                        <OffCanvasBody>
                            <div className='row g-4'>
                                <div className='col-12'>
                                    <FormGroup id='periodFrom' label='Period From'>
                                        <Input onChange={academicPeriodForm.handleChange} value={academicPeriodForm.values.periodFrom} onBlur={academicPeriodForm.handleBlur} isValid={academicPeriodForm.isValid} isTouched={academicPeriodForm.touched.periodFrom} invalidFeedback={academicPeriodForm.errors.periodFrom} type='date' />
                                    </FormGroup>
                                </div>

                                <div className='col-12'>
                                    <FormGroup id='periodTo' label='Period To'>
                                        <Input onChange={academicPeriodForm.handleChange} value={academicPeriodForm.values.periodTo} onBlur={academicPeriodForm.handleBlur} isValid={academicPeriodForm.isValid} isTouched={academicPeriodForm.touched.periodTo} invalidFeedback={academicPeriodForm.errors.periodTo} type='date' />
                                    </FormGroup>
                                </div>
                            </div>
                        </OffCanvasBody>
                        <div className='row m-0'>
                            <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                                <Button color='info' className='w-100' type="submit" icon="Save" isDisable={!academicPeriodForm.isValid && !!academicPeriodForm.submitCount}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </form>
                </OffCanvas>

                <OffCanvas setOpen={setUpdateAcademicPeriodOffcanvas} isOpen={updateAcademicPeriodOffcanvas} titleId='updateAcademicPeriodOffcanvas' isBodyScroll placement='end'>
                    <OffCanvasHeader setOpen={setUpdateAcademicPeriodOffcanvas} onClick={closeAndReset}>
                        <OffCanvasTitle id='updateAcademicPeriodOffcanvas'>Edit Academic Period</OffCanvasTitle>
                    </OffCanvasHeader>
                    <form noValidate onSubmit={academicPeriodEditForm.handleSubmit}>
                        <OffCanvasBody>
                            <div className='row g-4'>
                                <div className='col-12'>
                                    <FormGroup id='periodFrom' label='Period From'>
                                        <Input onChange={academicPeriodEditForm.handleChange} value={academicPeriodEditForm.values.periodFrom} onBlur={academicPeriodEditForm.handleBlur} isValid={academicPeriodEditForm.isValid} isTouched={academicPeriodEditForm.touched.periodFrom} invalidFeedback={academicPeriodEditForm.errors.periodFrom} type='date' />
                                    </FormGroup>
                                </div>

                                <div className='col-12'>
                                    <FormGroup id='periodTo' label='Period To'>
                                        <Input onChange={academicPeriodEditForm.handleChange} value={academicPeriodEditForm.values.periodTo} onBlur={academicPeriodEditForm.handleBlur} isValid={academicPeriodEditForm.isValid} isTouched={academicPeriodEditForm.touched.periodTo} invalidFeedback={academicPeriodEditForm.errors.periodTo} type='date' />
                                    </FormGroup>
                                </div>
                                <div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
                                    <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteAcademicPeriodSubmit} />
                                </div>
                            </div>
                        </OffCanvasBody>

                        <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                            <div className='row m-0'>
                                <div className='col-6 p-3'>
                                    <Button color='danger' className='w-100' icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                                        Delete
                                    </Button>
                                </div>
                                <div className='col-6 p-3'>
                                    <Button color='info' icon="Save" type="submit" className='w-100' isDisable={!academicPeriodEditForm.isValid && !!academicPeriodEditForm.submitCount}> Update</Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </OffCanvas>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper>
        </>
    )
}
export default AcademicPeriod;