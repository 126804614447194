import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../../../contexts/authContext";
import { useFormik } from "formik";
import { useReactToPrint } from "react-to-print";
import NoDataMsg from "../../../../common/components/NoDataMsg";
import useDarkMode from "../../../../hooks/useDarkMode";
import useSortableData from "../../../../hooks/useSortableData";
import Page from "../../../../layout/Page/Page";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import { getLicenseKey } from "../../../../services/application.settings";
import { updateFilter, getColumnsForDataTable, updateColumnsForDataTable } from "../../../../services/common.service";
import { downloadExcel, convertJsonToPdf, downloadFile } from "../../../../services/ExportService";
import { TableLoader } from "../../../../services/loader.services";
import { toasts } from "../../../../services/toast.service";
import { getSalesVehicleList } from "../../../../services/transport.service";
import Button, { ButtonGroup } from "../../../bootstrap/Button";
import Card, { CardHeader, CardLabel, CardTitle, CardActions, CardBody } from "../../../bootstrap/Card";
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from "../../../bootstrap/Dropdown";
import Checks, { ChecksGroup } from "../../../bootstrap/forms/Checks";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import PaginationButtons, { dataPagination } from "../../../PaginationButtons";
import Icon from "../../../icon/Icon";
import Input from "../../../bootstrap/forms/Input";



const SalesVechicleList = () => {

    useEffect(() => {
        getSalesVehicle()
    }, [])
    const [dataStatus, setDataStatus] = useState(false)
    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [vehicleDetailsData, setVehicleDetailsData] = useState([])
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    // ForModal And Tables States
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(vehicleDetailsData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [noDataMsg, setNoDataMsg] = useState('')

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function getSalesVehicle() {
        setDataStatus(false)
        getSalesVehicleList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.salesVehicleList;
                    if (data != undefined) {
                        setDataStatus(true)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        getColumnsForTable('getVehicleDetails', 'get');
                        setVehicleDetailsData(data);    
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true)
                    //toasts(response.data.message, "Error")
                    getColumnsForTable('getVehicleDetails', 'get');
                    setVehicleDetailsData([]);
                    setNoDataMsg(response.data.message)    
                } else {
                    setDataStatus(true)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getVehicleDetails', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <PageWrapper title="Sales Vehicle List">
            <Page container='fluid'>
                <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                    <CardHeader borderSize={1}>
                        <CardLabel icon='List' iconColor='info' className="col-lg-6">
                            <CardTitle tag='div' className='h5'>Sales Vehicle List</CardTitle>
                        </CardLabel>&nbsp;&nbsp;

                        <ButtonGroup className="col-lg-3 justify-content-end d-print-none" color='primary'>
                            <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                            <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                        </ButtonGroup>

                        <CardActions className="d-print-none">
                            <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                <DropdownToggle>
                                    <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                </DropdownToggle>
                                <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                    <DropdownItem>
                                        <div className='container py-2'>
                                            <form className='row g-3'>
                                                <div className='col-12'>
                                                    <FormGroup>
                                                        <h6>Select All</h6>
                                                        <Checks
                                                            id='available'
                                                            type='switch'
                                                            label='Select All Columns'
                                                            onChange={() => getColumnsForTable('getVehicleDetails', 'post')}
                                                            checked={isChecked}
                                                            ariaLabel='Available status'
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                    <FormGroup>
                                                        <h6>Columns</h6>
                                                        <ChecksGroup>
                                                            {allColumnsData.map((i: any) => (
                                                                <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                            ))}
                                                        </ChecksGroup>
                                                    </FormGroup>
                                                </div>
                                            </form>
                                        </div>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <Dropdown isButtonGroup>
                                <DropdownToggle>
                                    <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                </DropdownToggle>
                                <DropdownMenu isAlignmentEnd>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("Sales Vehicle List", vehicleDetailsData, columnVisibilityData)}> Excel </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(vehicleDetailsData, columnVisibilityData, "Sales Vehicle List")}> PDF
                                        </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("Sales Vehicle List", vehicleDetailsData, columnVisibilityData)}> CSV </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>

                        </CardActions>
                    </CardHeader>
                    <CardBody className='table-responsive' isScrollable >
                        {!dataStatus ?
                            <TableLoader /> :
                            <table className='table table-modern table-hover text-nowrap'>
                                <thead>
                                    <tr>
                                        <th scope='col' onClick={() => requestSort('sno')}
                                            className='cursor-pointer text-decoration-underline'>S.No{' '}
                                            <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                        </th>
                                        {columnVisibilityData.map((column: any) => (
                                            <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                className='cursor-pointer text-decoration-underline'>
                                                {column.columnName}
                                                <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData != "" ?
                                        <>
                                            {filteredData.map((rowData: any) => (
                                                <tr key={rowData.vehicleDetails_Id}>
                                                    <td>{rowData.sno}</td>
                                                    {columnVisibilityData.map((column: any) => (
                                                        (column.isDisplay && rowData[column.keyName]) ?
                                                            <td key={`${rowData.vehicleDetails_Id}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                    ))}
                                                </tr>
                                            ))
                                            } </>
                                        :
                                        <NoDataMsg columnsCount={columnVisibilityData.length + 1} msg={noDataMsg} />}
                                    {/* {filteredData.map((i: any) => (
                                    <tr key={i.vehicleDetails_Id}>
                                        <td>{i.sno}</td>
                                        {columnVisibilityData.map((column: any) => (<>
                                            {column.keyName == 'vehicleId' && column.isDisplay ? <td>{i.vehicleId}</td> : null}
                                        </>))}
                                        {columnVisibilityData.map((column: any) => (<>
                                            {column.keyName == 'vehicleType' && column.isDisplay ? <td>{i.vehicleType}</td> : null}
                                        </>))}
                                        {columnVisibilityData.map((column: any) => (<>
                                            {column.keyName == 'registrationNo' && column.isDisplay ? <td>{i.registrationNo}</td> : null}
                                        </>))}
                                        {columnVisibilityData.map((column: any) => (<>
                                            {column.keyName == 'registerOwner' && column.isDisplay ? <td>{i.registerOwner}</td> : null}
                                        </>))}
                                        {columnVisibilityData.map((column: any) => (<>
                                            {column.keyName == 'contactNo' && column.isDisplay ? <td>{i.contactNo}</td> : null}
                                        </>))}
                                        {columnVisibilityData.map((column: any) => (<>
                                            {column.keyName == 'remarks' && column.isDisplay ? <td>{i.remarks}</td> : null}
                                        </>))}
                                    </tr>
                                ))} */}
                                </tbody>
                            </table>
                        }
                    </CardBody>
                    <PaginationButtons
                        className='d-print-none'
                        data={items}
                        label='items'
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        perPage={perPage}
                        setPerPage={setPerPage}
                    />
                </Card>
            </Page>
        </PageWrapper>
    )
}

export default SalesVechicleList
