import React, { useContext, useEffect, useRef, useState } from 'react';
import { updateColumnsForDataTable, updateFilter } from '../../services/common.service';
import { useFormik } from 'formik';
import PaginationButtons, { dataPagination } from '../PaginationButtons';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../bootstrap/Card';
import Button, { ButtonGroup } from '../bootstrap/Button';
import Icon from '../icon/Icon';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../bootstrap/Dropdown';
import FormGroup from '../bootstrap/forms/FormGroup';
import Checks, { ChecksGroup } from '../bootstrap/forms/Checks';
import { convertJsonToPdf, downloadExcel, downloadFile } from '../../services/ExportService';
import useSortableData from '../../hooks/useSortableData';
import { getColumnsForDataTable } from '../../services/common.service';
import { toasts } from '../../services/toast.service';
import AuthContext from '../../contexts/authContext';
import { useReactToPrint } from 'react-to-print';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../bootstrap/OffCanvas';
import Input from '../bootstrap/forms/Input';
import DeleteComponents from '../../common/components/DeleteComponents';
import useDarkMode from '../../hooks/useDarkMode';
import { TableLoader, showLoader } from '../../services/loader.services';
import AlertService from '../../services/AlertService';

import NoDataMsg from '../../common/components/NoDataMsg';
import { addTournamentName, deleteTournamentName, getTournamentNameList, updateTournamentName } from '../../services/sportsManagement.service';

const AddTournament = () => {
	useEffect(() => {
		getTournament();
	}, []);

	const { themeStatus, darkModeStatus } = useDarkMode();
	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [tournamentData, setTournamentData] = useState([]);
	const { userAccountId, userTypeId } = useContext(AuthContext);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnDataById, setColumnDataById] = useState([]);
	const [isChecked, setIsChecked] = useState(false);
	const componentRef = useRef(null);

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(tournamentData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);
	const [addTournamentNameOffCanvas, setAddTournamentNameOffCanvas] = useState(false);
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [editTournamentOffCanvas, setEditTournamentOffCanvas] = useState(false);
	const [isDeleteOpen, setIsDeleteOpen] = useState(false);

	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);

	const [dataStatus, setDataStatus] = useState(false)
	const [noDataMsg, setNoDataMsg] = useState('')

	const tournamentForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			tournamentName: '',
		},
		validate: (values) => {
			const errors: {
				tournamentName?: string;
			} = {};
			if (!values.tournamentName) {
				errors.tournamentName = 'Required';
			}
			return errors;
		},

		onSubmit: () => {
			tournamentSubmit();
		},
	});

	const editTournamentForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			tournamentName: '',
			tournamentId: '',
		},
		validate: (values) => {
			const errors: {
				tournamentName?: string;
			} = {};
			if (!values.tournamentName) {
				errors.tournamentName = 'Required';
			}
			return errors;
		},

		onSubmit: () => {
			updateTournamentSubmit();
		},
	});

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },

		onSubmit: () => { },
	});

	const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

	function getTournament() {
		setDataStatus(false)
		getTournamentNameList(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.tournamentNameList;
					if (data != undefined) {
						setDataStatus(true)
						setTournamentData(data);
						getColumnsForTable('getTournamentList', 'get');
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
					} else {
						setDataStatus(true)
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setDataStatus(true)
					//toasts(response.data.message, 'Error');
					getColumnsForTable('getTournamentList', 'get');
					setTournamentData([]);
					setNoDataMsg(response.data.message)
				} else {
					setDataStatus(true)
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataStatus(true)
				toasts(error, 'Error');
			},
		);
	}

	function onActivate(i: any) {
		if (i != undefined) {
			let selectedTournament = i;
			setEditTournamentOffCanvas(true);

			editTournamentForm.setValues({
				tournamentName: selectedTournament.tournamentName,
				tournamentId: selectedTournament.tournamentId,
			});
		}
	}

	function setTournamentDetails() {
		return {
			licenseKey: 5566,
			userAccountId: 1,
			tournamentName: tournamentForm.values.tournamentName,
		};
	}

	function setEditTournamentDetails() {
		return {
			tournamentName: editTournamentForm.values.tournamentName,
			tournamentId: editTournamentForm.values.tournamentId,
			licenseKey: 5566,
			isActive: 1,
			userAccountId: 1,
		};
	}

	function tournamentSubmit() {
		showLoader(true);
		if (tournamentForm.isValid) {
			let tournamentPostData = setTournamentDetails();
			addTournamentName(
				tournamentPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						getTournament();
						closeAndFormReset();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (tournamentForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function updateTournamentSubmit() {
		showLoader(true);
		if (editTournamentForm.isValid) {
			let tournamentPostData = setEditTournamentDetails();
			updateTournamentName(
				tournamentPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						getTournament();
						closeAndFormReset();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'error' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (editTournamentForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function deleteTournamentSubmit() {
		showLoader(true);
		// if (editTournamentForm.isValid) {
		let tournamentPostData = setEditTournamentDetails()
		deleteTournamentName(tournamentPostData,
			(response) => {
				const data = response.data;
				if (data.success == true) {
					showLoader(false);
					setAlertStatus({ message: data.message, type: "success" });
					setIsOpen(true);
					getTournament();
					closeAndFormReset();
				}
				else if (data.success == false) {
					showLoader(false);
					setAlertStatus({ message: data.message, type: "error" });
					setIsOpen(true);
				}
				else {
					showLoader(false);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setAlertStatus({ message: errorDescription, type: "error" });
					setIsOpen(true);
				}
			}
			, (error) => {
				showLoader(false);
				setAlertStatus({ message: error, type: "error" });
				setIsOpen(true);
			}
		)
		// } else if (editTournamentForm.isValid == false) {
		// 	setAlertStatus({ message: 'Please fill all the details!', type: "error" });
		// 	setIsOpen(true);
		// }
	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(userTypeId, apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData)
						let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
						setColumnVisibilityData(columnVisibilityData)

						if (type == 'get') {
							const allData = allColumnsData.map((item: any) => item.isDisplay);
							const allColumns = allData.filter((item: any) => item == false);
							setIsChecked(allColumns == '' ? true : false);
						}
						else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

							for (let i = 0; i < nonDefaultValue.length; i++) {

								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
								}
								all.push(obj);
							}
							updateColumnsSubmit('', '', all)
						}
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)

	}


	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

		if (columnVisibilityForm.isValid) {

			setColumnDataById(isDisplay)
			const columnVisibilityPostData = {
				licenseKey: 5566,
				userAccountId: 1,
				columns: type ? type : [
					{
						columnVisibilityId: columnVisibilityId,
						isDisplay: isDisplay ? 0 : 1
					}
				]
			}

			updateColumnsForDataTable(columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getTournament', 'get');
					}
					else if (data.success == false) {
						// toasts(data.success, "Error")
					}
					else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, "Error")
					}
				}
				, (error) => {
					toasts(error, "Error")
				}
			)
		} else if (columnVisibilityForm.isValid == false) {
			toasts("Please fill all the details!", "Error")
		}
	}

	function closeAndFormReset() {
		setAddTournamentNameOffCanvas(false);
		setEditTournamentOffCanvas(false);
		setIsDeleteOpen(false);
		tournamentForm.resetForm();
		editTournamentForm.resetForm();
	}

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<PageWrapper title='Tournament List Data'>
			<Page container='fluid'>
				<Card stretch data-tour='list' ref={componentRef}>
					<CardHeader borderSize={1}>
						<CardLabel icon='List' iconColor='info' className='col-lg-5'>
							<CardTitle tag='div' className='h5'>
								Tournament List
							</CardTitle>
						</CardLabel>
						&nbsp;&nbsp;
						<ButtonGroup className='col-lg-3 d-print-none' color='primary'>
							<Icon className='mt-1' icon='Search' size='2x' color='primary' />
							<Input
								id='searchInput'
								type='search'
								placeholder='Search...'
								onChange={columnVisibilityForm.handleChange}
								value={columnVisibilityForm.values.searchInput}
							/>
						</ButtonGroup>
						<CardActions className='d-print-none'>
							<Dropdown
								isOpen={columnVisibilityMenu}
								setIsOpen={setColumnVisibilityMenu}
								isButtonGroup>
								<DropdownToggle>
									<Button icon='FilterAlt' color='primary' isLight>
										Filter
									</Button>
								</DropdownToggle>
								<DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
									<DropdownItem>
										<div className='container py-2'>
											<form className='row g-3'>
												<div className='col-12'>
													<FormGroup>
														<h6>Select All</h6>
														<Checks
															id='available'
															type='switch'
															label='Select All Columns'
															onChange={() =>
																getColumnsForTable(
																	'getTournamentList',
																	'post',
																)
															}
															checked={isChecked}
															ariaLabel='Available status'
														/>
													</FormGroup>
												</div>
												<div
													className='col-12'
													style={{
														maxHeight: '200px',
														overflowY: 'scroll',
													}}>
													<FormGroup>
														<h6>Columns</h6>
														<ChecksGroup>
															{allColumnsData.map((i: any) => (
																<Checks
																	key={i.columnVisibilityId}
																	id='{i.columnVisibilityId}'
																	label={i.columnName}
																	onChange={() =>
																		updateColumnsSubmit(
																			i.columnVisibilityId,
																			i.isDisplay,
																			'',
																		)
																	}
																	checked={i.isDisplay}
																	disabled={i.isDefault == true}
																/>
															))}
														</ChecksGroup>
													</FormGroup>
												</div>
											</form>
										</div>
									</DropdownItem>
								</DropdownMenu>
							</Dropdown>
							<Dropdown isButtonGroup>
								<DropdownToggle>
									<Button color='primary' isLight icon='CloudDownload'>
										{' '}
										Export{' '}
									</Button>
								</DropdownToggle>
								<DropdownMenu isAlignmentEnd>
									<DropdownItem>
										<Button
											color='primary'
											isLight
											icon='CloudDownload'
											onClick={() =>
												downloadExcel(
													'TournamentList',
													tournamentData,
													columnVisibilityData,
												)
											}>
											{' '}
											Excel{' '}
										</Button>
									</DropdownItem>
									<DropdownItem>
										<Button
											color='primary'
											isLight
											icon='PictureAsPdf'
											onClick={() =>
												convertJsonToPdf(
													tournamentData,
													columnVisibilityData,
													'TournamentList',
												)
											}>
											{' '}
											PDF
										</Button>
									</DropdownItem>
									<DropdownItem>
										<Button
											color='primary'
											isLight
											icon='CloudDownload'
											onClick={() =>
												downloadFile(
													'TournamentList',
													tournamentData,
													columnVisibilityData,
												)
											}>
											{' '}
											CSV{' '}
										</Button>
									</DropdownItem>
									<DropdownItem>
										<Button
											color='primary'
											isLight
											icon='Print'
											onClick={handlePrint}>
											{' '}
											Print{' '}
										</Button>
									</DropdownItem>
								</DropdownMenu>
							</Dropdown>
							<Button
								color='primary'
								icon='Add'
								isLight
								onClick={() => setAddTournamentNameOffCanvas(true)}>
								Add New
							</Button>
						</CardActions>
					</CardHeader>

					<CardBody className='table-responsive' isScrollable>
						{!dataStatus ?
							<TableLoader /> :
							<table className='table table-modern table-hover text-nowrap'>
								<thead>
									<tr>
										<th
											scope='col'
											onClick={() => requestSort('sno')}
											className='cursor-pointer text-decoration-underline'>
											S.No{' '}
											<Icon
												size='lg'
												className={getClassNamesFor('sno')}
												icon='FilterList'
											/>
										</th>
										{columnVisibilityData.map((column: any) => (
											<th
												key={column.keyName}
												scope='col'
												onClick={() => requestSort(column.keyName)}
												className='cursor-pointer text-decoration-underline'>
												{column.columnName}
												<Icon
													size='lg'
													className={getClassNamesFor(column.keyName)}
													icon='FilterList'
												/>
											</th>
										))}
										<th scope='col' className='d-print-none'>
											Action
										</th>
									</tr>
								</thead>
								<tbody>
									{filteredData != "" ?<>{filteredData.map((rowData: any) => (<tr key={rowData.tournamentId}><td width={20}>{rowData.sno}</td>{columnVisibilityData.map((column: any) =>column.isDisplay && rowData[column.keyName] ? (<td key={`${rowData.tournamentId}-${column.keyName}`}>{rowData[column.keyName]}</td>) : (<td key={`empty-${column.keyName}`} />))}<td className="d-print-none"><Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} /></td></tr>))}</>:
										<NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
								</tbody>
							</table>
						}
					</CardBody>
					<PaginationButtons
						className='d-print-none'
						data={items}
						label='items'
						setCurrentPage={setCurrentPage}
						currentPage={currentPage}
						perPage={perPage}
						setPerPage={setPerPage}
					/>
				</Card>
			</Page>

			<OffCanvas
				setOpen={setAddTournamentNameOffCanvas}
				isOpen={addTournamentNameOffCanvas}
				titleId='addTournamentNameOffCanvas'
				isBodyScroll
				tag='form'
				noValidate
				onSubmit={tournamentForm.handleSubmit}>
				<OffCanvasHeader
					setOpen={setAddTournamentNameOffCanvas}
					onClick={closeAndFormReset}>
					<OffCanvasTitle id='addTournamentNameOffCanvas'>Add Tournament</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					<div className='col-md-12'>
						<FormGroup id='tournamentName' label='Tournament Name'>
							<Input
								placeholder='Enter Tournament Name'
								onChange={tournamentForm.handleChange}
								value={tournamentForm.values.tournamentName}
								isValid={tournamentForm.isValid}
								onBlur={tournamentForm.handleBlur}
								isTouched={tournamentForm.touched.tournamentName}
								invalidFeedback={tournamentForm.errors.tournamentName}
							/>
						</FormGroup>
					</div>
				</OffCanvasBody>
				<div className='row m-0'>
					<div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
						<Button
							color='info'
							icon='Save'
							className='w-100'
							type='submit'
							isDisable={!tournamentForm.isValid && !!tournamentForm.submitCount}>
							Save
						</Button>
					</div>
				</div>
			</OffCanvas>

			<OffCanvas
				setOpen={setEditTournamentOffCanvas}
				isOpen={editTournamentOffCanvas}
				titleId='editTournamentOffCanvas'
				placement='end'>
				<OffCanvasHeader setOpen={setEditTournamentOffCanvas} onClick={closeAndFormReset}>
					<OffCanvasTitle id='editTournamentOffCanvas'>Update Tournament</OffCanvasTitle>
				</OffCanvasHeader>
				<form noValidate onSubmit={editTournamentForm.handleSubmit}>
					<OffCanvasBody>
						<div className="row">
							<div className='col-12'>
								<FormGroup id='tournamentName' label='Tournament Name'>
									<Input
										onChange={editTournamentForm.handleChange}
										value={editTournamentForm.values.tournamentName}
										isValid={editTournamentForm.isValid}
										onBlur={editTournamentForm.handleBlur}
										isTouched={editTournamentForm.touched.tournamentName}
										invalidFeedback={editTournamentForm.errors.tournamentName}
										placeholder='Enter Department Name'
									/>
								</FormGroup>
							</div>
							<div className='d-flex justify-content-center position-absolute top-65 start-50 translate-middle'>
								<DeleteComponents
									isDeleteOpen={isDeleteOpen}
									setIsDeleteOpen={setIsDeleteOpen}
									deleteFunction={deleteTournamentSubmit}
								/>
							</div>
						</div>

						<div className='row top-90 m-0'>
							<div className='col-6 position-absolute bottom-0 start-0 p-3'>
								<Button
									color='danger'
									className='w-100'
									icon='Delete'
									onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
									Delete
								</Button>
							</div>
							<div className='col-6 position-absolute bottom-0 end-0 p-3'>
								<Button
									color='info'
									icon='Save'
									className='w-100'
									type='submit'
									isDisable={
										!editTournamentForm.isValid &&
										!!editTournamentForm.submitCount
									}>
									Update
								</Button>
							</div>
						</div>
					</OffCanvasBody>
				</form>
			</OffCanvas>

			<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
		</PageWrapper>
	);
};

export default AddTournament;
