import { AxiosResponse } from 'axios';
import service from '../api/api.services';
import { getAPIURL, getLicenseKey } from './application.settings';

const api = service();

export const addAcademicPeriod = (
	academicPeriodPostData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getAPIURL() + `addAcademicPeriod`,
		academicPeriodPostData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : addAcademicPeriod');
		},
	);

export const getAcademicPeriod = (
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.get(
		getAPIURL() + `getAcademicPeriod/${getLicenseKey}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getAcademicPeriod - ' + error.message);
			console.log('error caught in service : getAcademicPeriod');
		},
	);

export const updateAcademicPeriod = (
	academicPeriodPostData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getAPIURL() + `updateAcademicPeriod`,
		academicPeriodPostData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : updateAcademicPeriod');
		},
	);

export const deleteAcademicPeriod = (
	academicPeriodPostData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getAPIURL() + `deleteAcademicPeriod`,
		academicPeriodPostData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : deleteAcademicPeriod');
		},
	);

export const getSectionDetails = (
	mediumId: any,
	standardDetailsId: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.get(
		getAPIURL() + `getSectionDetails/${getLicenseKey}/${mediumId}/${standardDetailsId}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getSectionDetails - ' + error.message);
			console.log('error caught in service : getSectionDetails');
		},
	);

	export const getSection = (
		standardDetailsId: any,
		success: (arg0: AxiosResponse<any, any>) => any,
		failure: (arg0: any) => void,
	) =>
		api.get(
			getAPIURL() + `getSectionDetails/${getLicenseKey}/0/${standardDetailsId}`,
			(response) => {
				success(response);
			},
			(error) => {
				failure('getSectionDetails - ' + error.message);
				console.log('error caught in service : getSectionDetails');
			},
		);

export const addSectionDetails = (
	sectionDetailsPostData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getAPIURL() + `addSectionDetails`,
		sectionDetailsPostData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : addSectionDetails');
		},
	);

export const updateSectionDetails = (
	sectionDetailsPostData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getAPIURL() + `updateSectionDetails`,
		sectionDetailsPostData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : updateSectionDetails');
		},
	);

export const deleteSectionDetails = (
	sectionDetailsPostData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getAPIURL() + `deleteSectionDetails`,
		sectionDetailsPostData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : deleteSectionDetails');
		},
	);

export const getCasteDetails = (
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.get(
		getAPIURL() + `getCasteDetails/${getLicenseKey}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getCasteDetails - ' + error.message);
			console.log('error caught in service : getCasteDetails');
		},
	);
export const addCasteDetails = (
	castePostData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getAPIURL() + `addCasteDetails`,
		castePostData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : addCasteDetails');
		},
	);
export const updateCasteDetails = (
	castePostData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getAPIURL() + `updateCasteDetails`,
		castePostData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : updateCasteDetails');
		},
	);
export const deleteCasteDetails = (
	castePostData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getAPIURL() + `deleteCasteDetails`,
		castePostData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : deleteCasteDetails');
		},
	);

export const getShift = (
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.get(
		getAPIURL() + `getShift/${getLicenseKey}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getShift - ' + error.message);
			console.log('error caught in service : getShift');
		},
	);

export const getMonth = (
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.get(
		getAPIURL() + `getMonth/${getLicenseKey}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getMonth - ' + error.message);
			console.log('error caught in service : getMonth');
		},
	);

export const getDayList = (
	academicPeriodId: string,
	monthId: string,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.get(
		getAPIURL() + `getDayList/${getLicenseKey}/${monthId}/${academicPeriodId}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getDayList - ' + error.message);
			console.log('error caught in service : getDayList');
		},
	);

export const getDayOrderType = (isDayOrder: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.get(
		getAPIURL() + `getDayOrderType/${getLicenseKey}/${isDayOrder}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getDayOrderType - ' + error.message);
			console.log('error caught in service : getDayOrderType');
		},
	);

export const addDayOrder = (
	dayOrderPostData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getAPIURL() + `addDayOrder`,
		dayOrderPostData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : addDayOrder');
		},
	);

export const getHolidayType = (
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.get(
		getAPIURL() + `getHolidayType/${getLicenseKey}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getHolidayType - ' + error.message);
			console.log('error caught in service : getHolidayType');
		},
	);

export const addHoliday = (
	holidayPostData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getAPIURL() + `addHoliday`,
		holidayPostData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : addHoliday');
		},
	);

export const addBatchMasterDetails = (
	batchMasterPostData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getAPIURL() + `addBatchMasterDetails`,
		batchMasterPostData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : addBatchMasterDetails');
		},
	);

export const getBatchMasterDetails = (
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.get(
		getAPIURL() + `getBatchMasterDetails/${getLicenseKey}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getBatchMasterDetails - ' + error.message);
			console.log('error caught in service : getBatchMasterDetails');
		},
	);

export const updateBatchMaster = (
	batchMasterPostData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getAPIURL() + `updateBatchMaster`,
		batchMasterPostData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : updateBatchMaster');
		},
	);

export const deleteBatchMaster = (
	batchMasterPostData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getAPIURL() + `deleteBatchMaster`,
		batchMasterPostData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : deleteBatchMaster');
		},
	);


export const getReferralType = (
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.get(
		getAPIURL() + `getReferralType/${getLicenseKey}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getReferralType - ' + error.message);
			console.log('error caught in service : getReferralType');
		},
	);

export const getUserListByUserTypeId = (
	referralTypeId: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.get(
		getAPIURL() + `getUserListByUserTypeId/${getLicenseKey}/${referralTypeId}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getUserListByUserTypeId - ' + error.message);
			console.log('error caught in service : getUserListByUserTypeId');
		},
	);
