import React, { lazy, useContext, useEffect, useState } from 'react';
import { RouteProps } from 'react-router-dom';
import {
	componentPagesMenu,
	dashboardPagesMenu,
	demoPagesMenu,
	gettingStartedPagesMenu,
	pageLayoutTypesPagesMenu
} from '../menu';
import Login from '../pages/presentation/auth/Login';
import AluminiLogin from '../pages/presentation/auth/AluminiLogin';
import AcademicPeriod from '../components/general/Master/AcademicPeriod';
import SectionCreation from '../components/general/Master/SectionCreation';
import CasteCreation from '../components/general/Master/CasteCreation';
import Period from '../components/general/Master/Period';
import DayOrder from '../components/general/Master/DayOrder';
import SchoolDetails from '../components/general/administration/SchoolDetails';
import ManagerUsers from '../components/general/administration/ManagerUsers';
import AssignScreensForUser from '../components/general/screens/AssignScreensForUser';
import AssignScreensForUserType from '../components/general/screens/AssignScreensForUserType';
import AdmissionFeesMaster from '../components/general/administration/AdmissionFeesMaster';
import StaffDesignation from '../components/general/administration/StaffDesignation';
import CreateStaff from '../components/academic/staff/CreateStaff';
import StaffList from '../components/academic/staff/StaffList';
import UpdateStaff from '../components/academic/staff/UpdateStaff';
import CreateSubject from '../components/academic/staff/subject-master/CreateSubject';
import StaffSubjectAllocation from '../components/academic/staff/subject-master/StaffSubjectAllocation';
import AreaMaster from '../components/management/transport/AreaMaster';
import StaffProfile from '../components/academic/staff/StaffProfile';
import StaffCertificateTracker from '../components/academic/staff/StaffCertificateTracker';
import RouteWistStudentList from '../components/management/transport/RouteWistStudentList';
import CreateVehicle from '../components/management/transport/vehicle-details/CreateVehicle';
import VehicleList from '../components/management/transport/vehicle-details/VehicleList';
import DeletedVechicleList from '../components/management/transport/vehicle-details/DeletedVehicleList';
import SalesVechicleList from '../components/management/transport/vehicle-details/SalesVehicleList';
import TyreCreation from '../components/management/transport/tyre-management/TyreCreation';
import TyreService from '../components/management/transport/tyre-management/TyreService';
import TyreServiceList from '../components/management/transport/tyre-management/TyreServiceList';
import VehicleSpareAndTaxDetails from '../components/management/transport/vehicle-details/VehicleSpareAndTaxDetails';
import VehicleScannedDocument from '../components/management/transport/vehicle-details/VehicleScannedDocument';
import FuelConsumptionEntry from '../components/management/fuel-consumption/FuelConsumptionEntry';
import FuelConsumptionReport from '../components/management/fuel-consumption/FuelConsumptionReport';
import VehicleTyreManagement from '../components/management/transport/tyre-management/VehicleTyreManagement';
import RouteMaster from '../components/management/transport/RouteMaster';
import RouteList from '../components/management/transport/RouteList';
import FeeMaster from '../components/general/administration/FeeMaster';
import BatchMaster from '../components/general/Master/BatchMaster';
import ClassCapacity from '../components/general/Master/ClassCapacity';
import TimeTableAllocation from '../components/general/Master/TimeTableAllocation';
import AddStudentWizard from '../components/academic/student/AddStudentWizard';
import TempStudentList from '../components/academic/student/temp-student/TempStudentList';
import TempStudentCreation from '../components/academic/student/temp-student/TempStudentCreation';
import CcAvenueReport from '../components/reports/accounts-report/CcAvenueReport';
import OverAllFeeReport from '../components/reports/accounts-report/OverAllFeeReport';
import AttendanceReport from '../components/reports/attendance-report/today-absentees-report/AttendanceReport';
import LabCoatFeeCollected from '../components/reports/staff-reports/LabCoatFeeCollected';
import StaffReferralReport from '../components/reports/staff-reports/StaffReferralReport';
import AreaWiseStudentReport from '../components/reports/student-report/AreaWiseStudentReport';
import CategoryWiseStudentList from '../components/reports/student-report/CategoryWiseStudentList';
import ClassWiseStudentList from '../components/reports/student-report/ClassWiseStudentList';
import OverAllStrength from '../components/reports/student-report/OverAllStrength';
import StudentReferralReport from '../components/reports/student-report/StudentReferralReport';
import TempStudentReport from '../components/reports/student-report/TempStudentReport';
import StudentList from '../components/academic/student/StudentList';
import LateAttendence from '../components/academic/attendance/LateAttendence';
import StudentAttendence from '../components/academic/attendance/StudentAttendence';
import TermFeesCollection from '../components/general/administration/feeCollection/TermFeesCollection';
import DiscontinuedStudentList from '../components/academic/student/DiscontinuedStudentList';
import AluminiStudentList from '../components/academic/student/AluminiStudentList';
import EditRollNumber from '../components/academic/student/EditRollNumber';
import StudentProfile from '../components/academic/student/StudentProfile';
import UpdateStudentWizard from '../components/academic/student/UpdateStudentWizard';
import StudentScholarshipReport from '../components/reports/student-report/StudentScholarshipReport';
import StudentOriginalCertificateReport from '../components/reports/student-report/StudentOriginalCertificateReport';
import TodayAbsenteesReport from '../components/reports/attendance-report/today-absentees-report/TodayAbsenteesReport';
import LateAttendanceReport from '../components/reports/attendance-report/LateAttendanceReport';
import AttendanceCertificate from '../components/academic/student/Certificate/Print/AttendanceCertificate';
import BonafideCertificateType1 from '../components/academic/student/Certificate/Print/BonafideCerticateType1';
import BonafideCertificateType2 from '../components/academic/student/Certificate/Print/BonafideCertificateType2';
import ConductCertificate from '../components/academic/student/Certificate/Print/ConductCertificate';
import CourseCompletionCertificate from '../components/academic/student/Certificate/Print/CourseCompletionCertificate';
import StudentCertificateTracker from '../components/academic/student/Certificate/StudentCertificateTracker';
import ManualTcEntry from '../components/academic/student/Certificate/manual-tc/ManualTcEntry';
import ManualTcList from '../components/academic/student/Certificate/manual-tc/ManualTcList';
import IssuedTCList from '../components/academic/student/Certificate/transfer-certificate/IssuedTcList';
import StudentTcApproval from '../components/academic/student/Certificate/transfer-certificate/StudentTcApproval';
import StudentTransferCertificate from '../components/academic/student/Certificate/transfer-certificate/StudentTransferCertificate';
import GeneralMessage from '../components/management/sms/GeneralMessage';
import AddTournament from '../components/sports-management/AddTournament';
import EventManager from '../components/sports-management/EventManager';
import ClassCapacityReport from '../components/reports/master-report/ClassCapacityReport';
import AddParticipants from '../components/sports-management/AddParticipants';
import ParticipantsList from '../components/sports-management/ParticipantsList';
import CommonTCToPrint from '../components/academic/student/Certificate/Print/CommonTCToPrint';
import StudentFeeDetails from '../components/academic/student/StudentFeeDetails';
import StudentRollBack from '../components/academic/student/StudentRollBack';
import ConcessionList from '../components/general/administration/concession/ConcessionList';
import DeletedStaffList from '../components/academic/staff/DeletedStaffList';
import LetsStarted from '../common/components/LetsStarted';
import QuickStudentCreation from '../components/academic/student/QuickStudentCreation';
import StudentSchoolName from '../components/general/administration/StudentSchoolName';
import BulkFeesCollection from '../components/general/administration/BulkFeesCollection';
import StaffPeriodSchedule from '../pages/presentation/dashboard/dashboard-component/StaffPeriodSchedule';
import ManualConcession from '../components/general/administration/concession/ManualConcession';
import TransportRange from '../components/management/transport/TransportRange';
import StaffSchedule from '../components/general/administration/StaffSchedule';
import StaffAttendanceList from '../components/academic/attendance/StaffAttendanceList';
import Module from '../components/general/Master/Module';
import CircularContent from '../pages/presentation/dashboard/dashboard-component/CircularContent';
import StudentSchedule from '../components/academic/student/StudentSchedule';
import StudentDashboardSchedule from '../pages/presentation/dashboard/dashboard-component/StudentDasboardSchedule';
import CircularDetails from '../pages/presentation/dashboard/dashboard-component/CircularDetails';
import EventDetails from '../components/sports-management/EventDetails';
import ExamHallAllocation from '../components/academic/exam/ExamHallAllocation';
import ExamTimeTable from '../components/academic/exam/ExamTimeTable';
import ExamDetails from '../components/academic/exam/ExamDetails';
import HallCreation from '../components/academic/exam/HallCreation';
import ExamSchedule from '../components/academic/exam/ExamSchedule';

const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),



	DASHBOARD_BOOKING: lazy(() => import('../pages/presentation/dashboard/DashboardBookingPage')),
	SUMMARY: lazy(() => import('../pages/presentation/SummaryPage')),
};

const screenMenus: RouteProps[] = [
	/**
	 * Getting Started
	*/
	{
		path: "general/addAcademicPeriod",
		element: <AcademicPeriod />
	},
	{
		path: "general/batchMaster",
		element: <BatchMaster />
	},
	{
		path: "general/addClassCapacity",
		element: <ClassCapacity />
	},
	{
		path: "general/addSectionDetails",
		element: <SectionCreation />
	},
	{
		path: "general/caste",
		element: <CasteCreation />
	},
	{
		path: "academic/addPeriod",
		element: <Period />
	},
	{
		path: "general/dayOrderList",
		element: <DayOrder />
	},
	{
		path: "academic/timeTableAllocation",
		element: <TimeTableAllocation />
	},
	{
		path: "academic/studentTcApproval",
		element: <StudentTcApproval />
	},
	

	/**
	 * Adminstration
	*/
	{
		path: "general/schoolList",
		element: <SchoolDetails />
	},
	{
		path: "general/manageUserList",
		element: <ManagerUsers />
	},
	{
		path: "general/screenAssign",
		element: <AssignScreensForUser />
	},
	{
		path: "general/assignUserScreen",
		element: <AssignScreensForUserType />
	},
	{
		path: "general/addApplictionFormFee",
		element: <AdmissionFeesMaster />
	},
	{
		path: "general/addStaffDesignation",
		element: <StaffDesignation />
	},
	{
		path: "general/feeMaster",
		element: <FeeMaster />
	},
	{
		path: "general/concessionList",
		element: <ConcessionList />
	},
	{
		path: "general/bulkFeesCollection",
		element: <BulkFeesCollection />
	},
	{
		path: "general/staffPeriodSchedule",
		element: <StaffPeriodSchedule />
	},
	{
		path: "general/addStudentSchool",
		element: <StudentSchoolName />
	},
	{
		path: "general/staffSchedule",
		element: <StaffSchedule />
	},
	{
		path: "general/addModule",
		element: <Module />
	},
	{
		path: "management/addEventDetails",
		element: <EventDetails />
	},

	/**
	 * Academic
	*/
	{
		path: "academic/addStaff",
		element: <CreateStaff />
	},
	{
		path: "academic/staffList",
		element: <StaffList />
	},
	{
		path: "academic/deletedStaffList",
		element: <DeletedStaffList />
	},
	{
		path: "academic/updateStaff/:staffDetailsId",
		element: <UpdateStaff />
	},
	{
		path: "academic/subjectPaperCreation",
		element: <CreateSubject />
	},
	{
		path: "academic/staffSubjectAllocation",
		element: <StaffSubjectAllocation />
	},
	{
		path: 'academic/studentTransferCertificate',
		element: <StudentTransferCertificate />,
	},
	{
		path: "academic/conductCertificate",
		element: <ConductCertificate />
	},
	{
		path: "academic/attendanceCertificate",
		element: <AttendanceCertificate />
	},
	{
		path: "academic/bonafideCertificateType1",
		element: <BonafideCertificateType1 />
	},
	{
		path: "academic/bonafideCertificateType2",
		element: <BonafideCertificateType2 />
	},
	{
		path: "academic/courseCompletionCertificate",
		element: <CourseCompletionCertificate />
	},
	
	{
		path: "academic/addTempStudentDetails",
		element: <TempStudentCreation />
	},
	{
		path: "academic/tempStudentList",
		element: <TempStudentList />
	},
	{
		path: "academic/manualTcEntry",
		element: <ManualTcEntry />
	},
	{
		path: "academic/manualTcList",
		element: <ManualTcList />
	},
	{
		path: "academic/forUgandPg",
		element: <IssuedTCList />
	},
	{
		path: "academic/studentList",
		element: <StudentList />
	},
	{
		path: "general/termFeesCollection",
		element: <TermFeesCollection />
	},
	{
		path: "general/termFeesCollection/:navigatedStudentDetailsId",
		element: <TermFeesCollection />
	},
	{
		path: "academic/studentAttendanceList",
		element: <StudentAttendence />
	},
	{
		path: "academic/lateAttendance",
		element: <LateAttendence />
	},
	{
		path: "academic/discountinuedStudentList",
		element: <DiscontinuedStudentList />
	},
	{
		path: "academic/aluminiStudentList",
		element: <AluminiStudentList />
	},
	{
		path: "academic/editRollNumber",
		element: <EditRollNumber />
	},
	{
		path: "academic/commonTc/:studentDetailsId",
		element: <CommonTCToPrint />
	},
	{
		path: "academic/commonTcForManual/:studentManualTcId",
		element: <CommonTCToPrint />
	},
	{
		path: "academic/studentCertificateTracker",
		element: <StudentCertificateTracker />
	},	
	{
		path: "academic/studentCertificateTracker/:navigatedStudentDetailsId",
		element: <StudentCertificateTracker />
	},
	{
		path: "academic/updateStudent/:studentDetailsId",
		element: <UpdateStudentWizard />
	},
	{
        path: 'general/manualConcession',
        element: <ManualConcession />,
    },
	{
		path: "academic/staffAttendanceList",
		element: <StaffAttendanceList />
	},
	{
		path: "academic/studentSchedule",
		element: <StudentSchedule />
	},
	{
		path: "academic/studentFeeDetails",
		element: <StudentFeeDetails />
	},
	{
		path: "academic/studentRollBack",
		element: <StudentRollBack />
	},
	{
		path: "academic/addStudent",
		element: <QuickStudentCreation />
	},
	
	{
		path: "general/addCircularDetails",
		element: <CircularContent />
	},
	{
		path: "general/circularDetails/:circularDetailsId",
		element: <CircularDetails />
	},
	{
		path: "academic/examHallAllocation",
		element: <ExamHallAllocation />
	},
	{
		path: "academic/examTimeTable",
		element: <ExamTimeTable />
	},
	{
		path: "academic/addExamDetails",
		element: <ExamDetails />
	},

	{
		path: "academic/addExamHall",
		element: <HallCreation />
	},

	{
		path: "academic/examSchedule",
		element: <ExamSchedule />
	},


	/**
	 * Management
	*/
	{
		path: "management/routeWistStudentList",
		element: <RouteWistStudentList />
	},
	{
		path: "management/createVehicle",
		element: <CreateVehicle />
	},
	{
		path: "management/vehicleList",
		element: <VehicleList />
	},
	{
		path: "management/deleteVehicleList",
		element: <DeletedVechicleList />
	},
	{
		path: "management/salesVehicleList",
		element: <SalesVechicleList />
	},
	{
		path: "management/tyreCreation",
		element: <TyreCreation />
	},
	{
		path: "management/tyreService",
		element: <TyreService />
	},
	{
		path: "management/tyreServiceList",
		element: <TyreServiceList />
	},
	{
		path: "management/addVehicleSpareAndTaxDetails",
		element: <VehicleSpareAndTaxDetails />
	},
	{
		path: "management/vehicleTyreLink",
		element: <VehicleTyreManagement />
	},
	{
		path: "management/vehicleScannedDocument",
		element: <VehicleScannedDocument />
	},
	{
		path: "management/fuelConsumptionEntry",
		element: <FuelConsumptionEntry />
	},
	{
		path: "management/fuelConsumptionReport",
		element: <FuelConsumptionReport />
	},		

	/*
	 SMS
	*/
	{
		path: "management/studentGeneralMessage",
		element: <GeneralMessage />
	},

	/*
	 Sports Management
	*/ 
	{
		path: "sportsManagement/addTournament",
		element: <AddTournament />
	},
	{
		path: "sportsManagement/eventManager",
		element: <EventManager />
	},
	{
		path: "sportsManagement/addParticipants/:tournamentDetailsId",
		element: <AddParticipants />
	},
	{
		path: "sportsManagement/ParticipantsList/:tournamentDetailsId",
		element: <ParticipantsList />
	},
	
	/**
	 * Transport
	*/
	{
		path: "management/areaMaster",
		element: <AreaMaster />
	},
	{
		path: "management/routeMaster",
		element: <RouteMaster />
	},
	{
		path: "management/routeList",
		element: <RouteList />
	},
	{
		path: "management/addTransportRange",
		element: <TransportRange />
	},

	/**
	 * Report
	*/
	{
		path: "reports/staffReferralReport",
		element: <StaffReferralReport />
	},
	{
		path: "reports/studentReferralReport",
		element: <StudentReferralReport />
	},
	{
		path: "reports/areaWiseStudentReport",
		element: <AreaWiseStudentReport />
	},
	{
		path: "reports/tempStudentReport",
		element: <TempStudentReport />
	},
	{
		path: "reports/classWiseStudentList",
		element: <ClassWiseStudentList />
	},
	{
		path: "reports/overAllStrength",
		element: <OverAllStrength />
	},
	{
		path: "reports/labCoatFeeCollected",
		element: <LabCoatFeeCollected />
	},
	{
		path: "reports/ccavReport",
		element: <CcAvenueReport />
	},
	{
		path: "reports/overAllFeeStructure",
		element: <OverAllFeeReport />
	},
	{
		path: "reports/attendanceReport",
		element: <AttendanceReport />
	},
	{
		path: "reports/categoryWiseStudentList",
		element: <CategoryWiseStudentList />
	},
	{
		path: "reports/studentScholarshipReport",
		element: <StudentScholarshipReport />
	},
	{
		path: "reports/studentOriginalCertificateReport",
		element: <StudentOriginalCertificateReport />
	},
	{
		path: "reports/todayAbsentReport",
		element: <TodayAbsenteesReport />
	},
	{
		path: "reports/lateAttendanceReport",
		element: <LateAttendanceReport />
	},
	{
		path: "reports/classCapacityReport",
		element: <ClassCapacityReport />
	},	

	{
		path: "academic/studentFeeDetails",
		element: <StudentFeeDetails />
	},
	/**
	 * Auth Page
	 */
	{
		path: demoPagesMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: demoPagesMenu.login.path,
		element: <Login />,
	},
	{
		path: demoPagesMenu.AluminiLogin.path,
		element: <AluminiLogin />,
	},

	/**
	 * Landing -- for DASHBOARD
	 */
	{
		path: '/dashboard',
		element: <LANDING.DASHBOARD />,
	},
	{
        path: "/followingStepsToHave",
        element: <LetsStarted />
    },
];

const commonMenus: RouteProps[] = [

	/**
	 * Academic
	 */
	{
		path: "academic/createStudent",
		element: <AddStudentWizard />
	},

	{
		path: "academic/updateStaff/:staffDetailsId",
		element: <UpdateStaff />
	},
	{
		path: "academic/staffProfile/:staffDetailsId",
		element: <StaffProfile />
	},	
	{
		path: "academic/staffCertificateTracker/:staffDetailsId",
		element: <StaffCertificateTracker />
	},
	{
		path: "general/termFeesCollection/:navigatedStudentDetailsId",
		element: <TermFeesCollection />
	},
	{
		path: "academic/studentProfile/:studentDetailsId",
		element: <StudentProfile />
	},
	{
		path: "academic/updateStudent/:studentDetailsId",
		element: <UpdateStudentWizard />
	},
	{
		path: "academic/bonafideCertificateType1/:navigatedStudentDetailsId",
		element: <BonafideCertificateType1 />
	},
	{
		path: "academic/bonafideCertificateType2/:navigatedStudentDetailsId",
		element: <BonafideCertificateType2 />
	},
	{
		path: "academic/studentCertificateTracker/:navigatedStudentDetailsId",
		element: <StudentCertificateTracker />
	},
	{
		path: "sportsManagement/addParticipants/:tournamentDetailsId",
		element: <AddParticipants />
	},
	{
		path: "sportsManagement/ParticipantsList/:tournamentDetailsId",
		element: <ParticipantsList />
	},
	{
		path: "academic/commonTc/:studentDetailsId",
		element: <CommonTCToPrint />
	},
	{
		path: "academic/commonTcForManual/:studentManualTcId",
		element: <CommonTCToPrint />
	},	
	{
        path: "/followingStepsToHave",
        element: <LetsStarted />
    },	
	{
		path: "general/circularDetails/:circularDetailsId",
		element: <CircularDetails />
	},
	
	
	/**
	 * Auth Page
	 */
	{
		path: demoPagesMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: demoPagesMenu.login.path,
		element: <Login />,
	},
	{
		path: demoPagesMenu.AluminiLogin.path,
		element: <AluminiLogin />,
	},

	/**
	 * Landing -- for DASHBOARD
	 */
	{
		path: '/dashboard',
		element: <LANDING.DASHBOARD />,
	},
	{
		path: "student/dashboard",
		element: <StudentDashboardSchedule />
	},
	{
		path: "academic/examSchedule",
		element: <ExamSchedule />
	},

];

const SINGLE = {
	BOXED: lazy(() => import('../pages/presentation/single-pages/SingleBoxedPage')),
	FLUID: lazy(() => import('../pages/presentation/single-pages/SingleFluidPage')),
};
const LIST = {
	BOXED: lazy(() => import('../pages/presentation/demo-pages/ListBoxedPage')),
	FLUID: lazy(() => import('../pages/presentation/demo-pages/ListFluidPage')),
};
const GRID = {
	BOXED: lazy(() => import('../pages/presentation/demo-pages/GridBoxedPage')),
	FLUID: lazy(() => import('../pages/presentation/demo-pages/GridFluidPage')),
};
const EDIT = {
	MODERN: lazy(() => import('../pages/presentation/demo-pages/EditModernPage')),
	BOXED: lazy(() => import('../pages/presentation/demo-pages/EditBoxedPage')),
	FLUID: lazy(() => import('../pages/presentation/demo-pages/EditFluidPage')),
	WIZARD: lazy(() => import('../pages/presentation/demo-pages/EditWizardPage')),
	IN_CANVAS: lazy(() => import('../pages/presentation/demo-pages/EditInCanvasPage')),
	IN_MODAL: lazy(() => import('../pages/presentation/demo-pages/EditInModalPage')),
};
const PRICING = {
	PRICING_TABLE: lazy(() => import('../pages/presentation/pricing/PricingTablePage')),
};
const APP = {
	PROJECT_MANAGEMENT: {
		PROJECTS_LIST: lazy(
			() => import('../pages/presentation/project-management/ProjectManagementsList'),
		),
		PROJECT: lazy(
			() => import('../pages/presentation/project-management/ProjectManagementsProject'),
		),
	},
	KNOWLEDGE: {
		GRID: lazy(() => import('../pages/presentation/knowledge/KnowledgeGridPage')),
		VIEW: lazy(() => import('../pages/presentation/knowledge/KnowledgeViewPage')),
	},
	SALES: {
		TRANSACTIONS: lazy(() => import('../pages/presentation/sales/TransActionsPage')),
		PRODUCTS: lazy(() => import('../pages/presentation/sales/SalesListPage')),
		PRODUCTS_GRID: lazy(() => import('../pages/presentation/sales/ProductsGridPage')),
		PRODUCTS_VIEW: lazy(() => import('../pages/presentation/sales/ProductViewPage')),
	},
	APPOINTMENT: {
		CALENDAR: lazy(() => import('../pages/presentation/appointment/CalendarPage')),
		EMPLOYEE_LIST: lazy(() => import('../pages/presentation/appointment/EmployeeList')),
		EMPLOYEE_VIEW: lazy(() => import('../pages/presentation/appointment/EmployeePage')),
		APPOINTMENT_LIST: lazy(() => import('../pages/presentation/appointment/AppointmentList')),
	},
	CRM: {
		CRM_DASHBOARD: lazy(() => import('../pages/presentation/crm/CrmDashboard')),
		CUSTOMERS: lazy(() => import('../pages/presentation/crm/CustomersList')),
		CUSTOMER: lazy(() => import('../pages/presentation/crm/Customer')),
	},
	CHAT: {
		WITH_LIST: lazy(() => import('../pages/presentation/chat/WithListChatPage')),
		ONLY_LIST: lazy(() => import('../pages/presentation/chat/OnlyListChatPage')),
	},
};
const PAGE_LAYOUTS = {
	HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
	HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
	SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
	CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
	BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
};
const CONTENT = {
	CONTENTS: lazy(() => import('../pages/documentation/content/ContentListPage')),
	TYPOGRAPHY: lazy(() => import('../pages/documentation/content/TypographyPage')),
	IMAGES: lazy(() => import('../pages/documentation/content/ImagesPage')),
	TABLES: lazy(() => import('../pages/documentation/content/TablesPage')),
	FIGURES: lazy(() => import('../pages/documentation/content/FiguresPage')),
};
const FORMS_PAGE = {
	FORMS: lazy(() => import('../pages/documentation/forms/FormsListPage')),
	FORM_GROUP: lazy(() => import('../pages/documentation/forms/FormGroupPage')),
	FORM_CONTROLS: lazy(() => import('../pages/documentation/forms/FormControlsPage')),
	SELECT: lazy(() => import('../pages/documentation/forms/SelectPage')),
	CHECKS_AND_RADIO: lazy(() => import('../pages/documentation/forms/ChecksAndRadioPage')),
	RANGE: lazy(() => import('../pages/documentation/forms/RangePage')),
	INPUT_GROUP: lazy(() => import('../pages/documentation/forms/InputGroupPage')),
	VALIDATION: lazy(() => import('../pages/documentation/forms/ValidationPage')),
	WIZARD: lazy(() => import('../pages/documentation/forms/WizardPage')),
};
const GETTING_STARTED = {
	INSTALLATION: lazy(() => import('../pages/documentation/getting-started/InstallationPage')),
	DEVELOPMENT: lazy(() => import('../pages/documentation/getting-started/DevelopmentPage')),
	FOLDER: lazy(() => import('../pages/documentation/getting-started/FolderStructurePage')),
	BOOTSTRAP: lazy(() => import('../pages/documentation/getting-started/BootstrapVariablesPage')),
	PROJECT: lazy(() => import('../pages/documentation/getting-started/ProjectStructurePage')),
};
const ROUTES = {
	ROUTER: lazy(() => import('../pages/documentation/routes/RouterPage')),
};
const COMPONENTS_PAGE = {
	COMPONENTS: lazy(() => import('../pages/documentation/components/ComponentsListPage')),
	ACCORDION: lazy(() => import('../pages/documentation/components/AccordionPage')),
	ALERT: lazy(() => import('../pages/documentation/components/AlertPage')),
	BADGE: lazy(() => import('../pages/documentation/components/BadgePage')),
	BREADCRUMB: lazy(() => import('../pages/documentation/components/BreadcrumbPage')),
	BUTTON: lazy(() => import('../pages/documentation/components/ButtonPage')),
	BUTTON_GROUP: lazy(() => import('../pages/documentation/components/ButtonGroupPage')),
	CARD: lazy(() => import('../pages/documentation/components/CardPage')),
	CAROUSEL: lazy(() => import('../pages/documentation/components/CarouselPage')),
	COLLAPSE: lazy(() => import('../pages/documentation/components/CollapsePage')),
	DROPDOWN: lazy(() => import('../pages/documentation/components/DropdownsPage')),
	LIST_GROUP: lazy(() => import('../pages/documentation/components/ListGroupPage')),
	MODAL: lazy(() => import('../pages/documentation/components/ModalPage')),
	NAVS_TABS: lazy(() => import('../pages/documentation/components/NavsTabsPage')),
	OFF_CANVAS: lazy(() => import('../pages/documentation/components/OffCanvasPage')),
	PAGINATION: lazy(() => import('../pages/documentation/components/PaginationPage')),
	POPOVERS: lazy(() => import('../pages/documentation/components/PopoversPage')),
	PROGRESS: lazy(() => import('../pages/documentation/components/ProgressPage')),
	SCROLLSPY: lazy(() => import('../pages/documentation/components/ScrollspyPage')),
	SPINNER: lazy(() => import('../pages/documentation/components/SpinnersPage')),
	TABLE: lazy(() => import('../pages/documentation/components/TablePage')),
	TOASTS: lazy(() => import('../pages/documentation/components/ToastsPage')),
	TOOLTIP: lazy(() => import('../pages/documentation/components/TooltipPage')),
};
const UTILITIES = {
	UTILITIES: lazy(() => import('../pages/documentation/utilities/UtilitiesListPage')),
	API: lazy(() => import('../pages/documentation/utilities/ApiPage')),
	BACKGROUND: lazy(() => import('../pages/documentation/utilities/BackgroundPage')),
	BORDERS: lazy(() => import('../pages/documentation/utilities/BordersPage')),
	COLORS: lazy(() => import('../pages/documentation/utilities/ColorsPage')),
	DISPLAY: lazy(() => import('../pages/documentation/utilities/DisplayPage')),
	FLEX: lazy(() => import('../pages/documentation/utilities/FlexPage')),
	FLOAT: lazy(() => import('../pages/documentation/utilities/FloatPage')),
	INTERACTIONS: lazy(() => import('../pages/documentation/utilities/InteractionsPage')),
	OVERFLOW: lazy(() => import('../pages/documentation/utilities/OverflowPage')),
	POSITION: lazy(() => import('../pages/documentation/utilities/PositionPage')),
	SHADOWS: lazy(() => import('../pages/documentation/utilities/ShadowsPage')),
	SIZING: lazy(() => import('../pages/documentation/utilities/SizingPage')),
	SPACING: lazy(() => import('../pages/documentation/utilities/SpacingPage')),
	TEXT: lazy(() => import('../pages/documentation/utilities/TextPage')),
	VERTICAL_ALIGN: lazy(() => import('../pages/documentation/utilities/VerticalAlignPage')),
	VISIBILITY: lazy(() => import('../pages/documentation/utilities/VisibilityPage')),
};
const ICONS = {
	ICONS_LIST: lazy(() => import('../pages/documentation/icons/IconsListPage')),
	ICON: lazy(() => import('../pages/documentation/icons/IconPage')),
	MATERIAL: lazy(() => import('../pages/documentation/icons/MaterialPage')),
};
const CHARTS_PAGE = {
	CHART_LIST: lazy(() => import('../pages/documentation/charts/ChartsListPage')),
	GENERAL_USAGE: lazy(() => import('../pages/documentation/charts/ChartGeneralUsagePage')),
	SPARKLINE: lazy(() => import('../pages/documentation/charts/ChartSparklinePage')),
	LINE: lazy(() => import('../pages/documentation/charts/ChartLinePage')),
	AREA: lazy(() => import('../pages/documentation/charts/ChartAreaPage')),
	COLUMN: lazy(() => import('../pages/documentation/charts/ChartColumnPage')),
	BAR: lazy(() => import('../pages/documentation/charts/ChartBarPage')),
	MIXED: lazy(() => import('../pages/documentation/charts/ChartMixedPage')),
	TIMELINE: lazy(() => import('../pages/documentation/charts/ChartTimelinePage')),
	CANDLESTICK: lazy(() => import('../pages/documentation/charts/ChartCandlestickPage')),
	BOX_WHISKER: lazy(() => import('../pages/documentation/charts/ChartBoxWhiskerPage')),
	PIE_DONUT: lazy(() => import('../pages/documentation/charts/ChartPieDonutPage')),
	RADAR: lazy(() => import('../pages/documentation/charts/ChartRadarPage')),
	POLAR: lazy(() => import('../pages/documentation/charts/ChartPolarPage')),
	RADIAL_BAR: lazy(() => import('../pages/documentation/charts/ChartRadialBarPage')),
	BUBBLE: lazy(() => import('../pages/documentation/charts/ChartBubblePage')),
	SCATTER: lazy(() => import('../pages/documentation/charts/ChartScatterPage')),
	HEAT_MAP: lazy(() => import('../pages/documentation/charts/ChartHeatMapPage')),
	TREE_MAP: lazy(() => import('../pages/documentation/charts/ChartTreeMapPage')),
};
const EXTRA = {
	NOTIFICATION: lazy(() => import('../pages/documentation/extras/NotificationPage')),
	HOOKS: lazy(() => import('../pages/documentation/extras/HooksPage')),
};
const presentation: RouteProps[] = [
	/**
	 * Landing -- for DASHBOARD
	 */
	{
		path: '/dashboard',
		element: <LANDING.DASHBOARD />,
	},
	{
		path: "academic/studentFeeDetails",
		element: <StudentFeeDetails />
	},
	{
		path: dashboardPagesMenu.dashboardBooking.path,
		element: <LANDING.DASHBOARD_BOOKING />,
	},
	{
		path: dashboardPagesMenu.summary.path,
		element: <LANDING.SUMMARY />,
	},


	/** ************************************************** */

	/**
	 * Pages
	 */

	/**
	 * Single Pages
	 */
	{
		path: demoPagesMenu.singlePages.subMenu.boxedSingle.path,
		element: <SINGLE.BOXED />,
	},
	{
		path: demoPagesMenu.singlePages.subMenu.fluidSingle.path,
		element: <SINGLE.FLUID />,
	},

	/**
	 * List
	 */
	{
		path: demoPagesMenu.listPages.subMenu.listBoxed.path,
		element: <LIST.BOXED />,
	},
	{
		path: demoPagesMenu.listPages.subMenu.listFluid.path,
		element: <LIST.FLUID />,
	},

	/**
	 * Grid
	 */
	{
		path: demoPagesMenu.gridPages.subMenu.gridBoxed.path,
		element: <GRID.BOXED />,
	},
	{
		path: demoPagesMenu.gridPages.subMenu.gridFluid.path,
		element: <GRID.FLUID />,
	},

	/**
	 * Edit
	 */
	{
		path: demoPagesMenu.editPages.subMenu.editModern.path,
		element: <EDIT.MODERN />,
	},
	{
		path: demoPagesMenu.editPages.subMenu.editBoxed.path,
		element: <EDIT.BOXED />,
	},
	{
		path: demoPagesMenu.editPages.subMenu.editFluid.path,
		element: <EDIT.FLUID />,
	},
	{
		path: demoPagesMenu.editPages.subMenu.editWizard.path,
		element: <EDIT.WIZARD />,
	},
	{
		path: demoPagesMenu.editPages.subMenu.editInCanvas.path,
		element: <EDIT.IN_CANVAS />,
	},
	{
		path: demoPagesMenu.editPages.subMenu.editInModal.path,
		element: <EDIT.IN_MODAL />,
	},

	{
		path: demoPagesMenu.pricingTable.path,
		element: <PRICING.PRICING_TABLE />,
	},

	/**
	 * END - Pages
	 */

	/**
	 * Auth Page
	 */
	{
		path: demoPagesMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: demoPagesMenu.login.path,
		element: <Login />,
	},
	{
		path: demoPagesMenu.signUp.path,
		element: <Login isSignUp />,
	},

	/**
	 * App
	 */

	/**
	 * App > Project Management
	 */
	{
		path: demoPagesMenu.projectManagement.subMenu.list.path,
		element: <APP.PROJECT_MANAGEMENT.PROJECTS_LIST />,
	},
	{
		path: `${demoPagesMenu.projectManagement.subMenu.itemID.path}/:id`,
		element: <APP.PROJECT_MANAGEMENT.PROJECT />,
	},

	/**
	 * App > Knowledge
	 */
	{
		path: demoPagesMenu.knowledge.subMenu.grid.path,
		element: <APP.KNOWLEDGE.GRID />,
	},
	{
		path: `${demoPagesMenu.knowledge.subMenu.itemID.path}/:id`,
		element: <APP.KNOWLEDGE.VIEW />,
	},

	/**
	 * App > Sales
	 */
	{
		path: demoPagesMenu.sales.subMenu.transactions.path,
		element: <APP.SALES.TRANSACTIONS />,
	},
	{
		path: demoPagesMenu.sales.subMenu.salesList.path,
		element: <APP.SALES.PRODUCTS />,
	},
	{
		path: demoPagesMenu.sales.subMenu.productsGrid.path,
		element: <APP.SALES.PRODUCTS_GRID />,
	},
	{
		path: `${demoPagesMenu.sales.subMenu.productID.path}/:id`,
		element: <APP.SALES.PRODUCTS_VIEW />,
	},

	/**
	 * App > Appointment
	 */
	{
		path: demoPagesMenu.appointment.subMenu.calendar.path,
		element: <APP.APPOINTMENT.CALENDAR />,
	},
	{
		path: demoPagesMenu.appointment.subMenu.employeeList.path,
		element: <APP.APPOINTMENT.EMPLOYEE_LIST />,
	},
	{
		path: `${demoPagesMenu.appointment.subMenu.employeeID.path}/:id`,
		element: <APP.APPOINTMENT.EMPLOYEE_VIEW />,
	},
	{
		path: demoPagesMenu.appointment.subMenu.appointmentList.path,
		element: <APP.APPOINTMENT.APPOINTMENT_LIST />,
	},

	/**
	 * App > CRM
	 */
	{
		path: demoPagesMenu.crm.subMenu.dashboard.path,
		element: <APP.CRM.CRM_DASHBOARD />,
	},
	{
		path: demoPagesMenu.crm.subMenu.customersList.path,
		element: <APP.CRM.CUSTOMERS />,
	},
	{
		path: `${demoPagesMenu.crm.subMenu.customerID.path}/:id`,
		element: <APP.CRM.CUSTOMER />,
	},

	/**
	 * App > Chat
	 */
	{
		path: demoPagesMenu.chat.subMenu.withListChat.path,
		element: <APP.CHAT.WITH_LIST />,
	},
	{
		path: demoPagesMenu.chat.subMenu.onlyListChat.path,
		element: <APP.CHAT.ONLY_LIST />,
	},

	/**
	 * END - App
	 */

	/** ************************************************** */

	/**
	 * Page Layout Types
	 */
	{
		path: pageLayoutTypesPagesMenu.blank.path,
		element: <PAGE_LAYOUTS.BLANK />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.headerAndSubheader.path,
		element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyHeader.path,
		element: <PAGE_LAYOUTS.HEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlySubheader.path,
		element: <PAGE_LAYOUTS.SUBHEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyContent.path,
		element: <PAGE_LAYOUTS.CONTENT />,
	},
	{
		path: pageLayoutTypesPagesMenu.asideTypes.subMenu.defaultAside.path,
		element: <PAGE_LAYOUTS.ASIDE />,
	},
	{
		path: pageLayoutTypesPagesMenu.asideTypes.subMenu.minimizeAside.path,
		element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
	},
];
const documentation: RouteProps[] = [
	/**
	 * Getting Started
	 */
	{
		path: gettingStartedPagesMenu.gettingStarted.subMenu.installation.path,
		element: <GETTING_STARTED.INSTALLATION />,
	},
	{
		path: gettingStartedPagesMenu.gettingStarted.subMenu.dev.path,
		element: <GETTING_STARTED.DEVELOPMENT />,
	},
	{
		path: gettingStartedPagesMenu.gettingStarted.subMenu.folderStructure.path,
		element: <GETTING_STARTED.FOLDER />,
	},
	{
		path: gettingStartedPagesMenu.gettingStarted.subMenu.bootstrapVariables.path,
		element: <GETTING_STARTED.BOOTSTRAP />,
	},
	{
		path: gettingStartedPagesMenu.gettingStarted.subMenu.projectStructure.path,
		element: <GETTING_STARTED.PROJECT />,
	},
	/**
	 * Routes
	 */
	{
		path: gettingStartedPagesMenu.routes.subMenu.router.path,
		element: <ROUTES.ROUTER />,
	},
	/**
	 * Bootstrap
	 */

	/**
	 * Content
	 */
	{
		path: componentPagesMenu.content.path,
		element: <CONTENT.CONTENTS />,
	},
	{
		path: componentPagesMenu.content.subMenu.typography.path,
		element: <CONTENT.TYPOGRAPHY />,
	},
	{
		path: componentPagesMenu.content.subMenu.images.path,
		element: <CONTENT.IMAGES />,
	},
	{
		path: componentPagesMenu.content.subMenu.tables.path,
		element: <CONTENT.TABLES />,
	},
	{
		path: componentPagesMenu.content.subMenu.figures.path,
		element: <CONTENT.FIGURES />,
	},

	/**
	 * Forms
	 */
	{
		path: componentPagesMenu.forms.path,
		element: <FORMS_PAGE.FORMS />,
	},
	{
		path: componentPagesMenu.forms.subMenu.formGroup.path,
		element: <FORMS_PAGE.FORM_GROUP />,
	},
	{
		path: componentPagesMenu.forms.subMenu.formControl.path,
		element: <FORMS_PAGE.FORM_CONTROLS />,
	},
	{
		path: componentPagesMenu.forms.subMenu.select.path,
		element: <FORMS_PAGE.SELECT />,
	},
	{
		path: componentPagesMenu.forms.subMenu.checksAndRadio.path,
		element: <FORMS_PAGE.CHECKS_AND_RADIO />,
	},
	{
		path: componentPagesMenu.forms.subMenu.range.path,
		element: <FORMS_PAGE.RANGE />,
	},
	{
		path: componentPagesMenu.forms.subMenu.inputGroup.path,
		element: <FORMS_PAGE.INPUT_GROUP />,
	},
	{
		path: componentPagesMenu.forms.subMenu.validation.path,
		element: <FORMS_PAGE.VALIDATION />,
	},
	{
		path: componentPagesMenu.forms.subMenu.wizard.path,
		element: <FORMS_PAGE.WIZARD />,
	},

	/**
	 * Components
	 */
	{
		path: componentPagesMenu.components.path,
		element: <COMPONENTS_PAGE.COMPONENTS />,
	},
	{
		path: componentPagesMenu.components.subMenu.tooltip.path,
		element: <COMPONENTS_PAGE.TOOLTIP />,
	},
	{
		path: componentPagesMenu.components.subMenu.toasts.path,
		element: <COMPONENTS_PAGE.TOASTS />,
	},
	{
		path: componentPagesMenu.components.subMenu.scrollspy.path,
		element: <COMPONENTS_PAGE.SCROLLSPY />,
	},
	{
		path: componentPagesMenu.components.subMenu.carousel.path,
		element: <COMPONENTS_PAGE.CAROUSEL />,
	},
	{
		path: componentPagesMenu.components.subMenu.spinners.path,
		element: <COMPONENTS_PAGE.SPINNER />,
	},
	{
		path: componentPagesMenu.components.subMenu.listGroup.path,
		element: <COMPONENTS_PAGE.LIST_GROUP />,
	},
	{
		path: componentPagesMenu.components.subMenu.breadcrumb.path,
		element: <COMPONENTS_PAGE.BREADCRUMB />,
	},
	{
		path: componentPagesMenu.components.subMenu.collapse.path,
		element: <COMPONENTS_PAGE.COLLAPSE />,
	},
	{
		path: componentPagesMenu.components.subMenu.pagination.path,
		element: <COMPONENTS_PAGE.PAGINATION />,
	},
	{
		path: componentPagesMenu.components.subMenu.progress.path,
		element: <COMPONENTS_PAGE.PROGRESS />,
	},
	{
		path: componentPagesMenu.components.subMenu.card.path,
		element: <COMPONENTS_PAGE.CARD />,
	},
	{
		path: componentPagesMenu.components.subMenu.button.path,
		element: <COMPONENTS_PAGE.BUTTON />,
	},
	{
		path: componentPagesMenu.components.subMenu.buttonGroup.path,
		element: <COMPONENTS_PAGE.BUTTON_GROUP />,
	},
	{
		path: componentPagesMenu.components.subMenu.alert.path,
		element: <COMPONENTS_PAGE.ALERT />,
	},
	{
		path: componentPagesMenu.components.subMenu.badge.path,
		element: <COMPONENTS_PAGE.BADGE />,
	},
	{
		path: componentPagesMenu.components.subMenu.popovers.path,
		element: <COMPONENTS_PAGE.POPOVERS />,
	},
	{
		path: componentPagesMenu.components.subMenu.dropdowns.path,
		element: <COMPONENTS_PAGE.DROPDOWN />,
	},
	{
		path: componentPagesMenu.components.subMenu.accordion.path,
		element: <COMPONENTS_PAGE.ACCORDION />,
	},
	{
		path: componentPagesMenu.components.subMenu.modal.path,
		element: <COMPONENTS_PAGE.MODAL />,
	},
	{
		path: componentPagesMenu.components.subMenu.navsTabs.path,
		element: <COMPONENTS_PAGE.NAVS_TABS />,
	},
	{
		path: componentPagesMenu.components.subMenu.offcanvas.path,
		element: <COMPONENTS_PAGE.OFF_CANVAS />,
	},
	{
		path: componentPagesMenu.components.subMenu.table.path,
		element: <COMPONENTS_PAGE.TABLE />,
	},

	/**
	 * Utilities
	 */
	{
		path: componentPagesMenu.utilities.path,
		element: <UTILITIES.UTILITIES />,
	},
	{
		path: componentPagesMenu.utilities.subMenu.api.path,
		element: <UTILITIES.API />,
	},
	{
		path: componentPagesMenu.utilities.subMenu.background.path,
		element: <UTILITIES.BACKGROUND />,
	},
	{
		path: componentPagesMenu.utilities.subMenu.borders.path,
		element: <UTILITIES.BORDERS />,
	},
	{
		path: componentPagesMenu.utilities.subMenu.colors.path,
		element: <UTILITIES.COLORS />,
	},
	{
		path: componentPagesMenu.utilities.subMenu.display.path,
		element: <UTILITIES.DISPLAY />,
	},
	{
		path: componentPagesMenu.utilities.subMenu.flex.path,
		element: <UTILITIES.FLEX />,
	},
	{
		path: componentPagesMenu.utilities.subMenu.float.path,
		element: <UTILITIES.FLOAT />,
	},
	{
		path: componentPagesMenu.utilities.subMenu.interactions.path,
		element: <UTILITIES.INTERACTIONS />,
	},
	{
		path: componentPagesMenu.utilities.subMenu.overflow.path,
		element: <UTILITIES.OVERFLOW />,
	},
	{
		path: componentPagesMenu.utilities.subMenu.position.path,
		element: <UTILITIES.POSITION />,
	},
	{
		path: componentPagesMenu.utilities.subMenu.shadows.path,
		element: <UTILITIES.SHADOWS />,
	},
	{
		path: componentPagesMenu.utilities.subMenu.sizing.path,
		element: <UTILITIES.SIZING />,
	},
	{
		path: componentPagesMenu.utilities.subMenu.spacing.path,
		element: <UTILITIES.SPACING />,
	},
	{
		path: componentPagesMenu.utilities.subMenu.text.path,
		element: <UTILITIES.TEXT />,
	},
	{
		path: componentPagesMenu.utilities.subMenu.verticalAlign.path,
		element: <UTILITIES.VERTICAL_ALIGN />,
	},
	{
		path: componentPagesMenu.utilities.subMenu.visibility.path,
		element: <UTILITIES.VISIBILITY />,
	},

	/**
	 * Extra
	 */

	/**
	 * Icons
	 */
	{
		path: componentPagesMenu.icons.path,
		element: <ICONS.ICONS_LIST />,
	},
	{
		path: componentPagesMenu.icons.subMenu.icon.path,
		element: <ICONS.ICON />,
	},
	{
		path: componentPagesMenu.icons.subMenu.material.path,
		element: <ICONS.MATERIAL />,
	},

	/**
	 * Charts
	 */
	{
		path: componentPagesMenu.charts.path,
		element: <CHARTS_PAGE.CHART_LIST />,
	},
	{
		path: componentPagesMenu.charts.subMenu.chartsUsage.path,
		element: <CHARTS_PAGE.GENERAL_USAGE />,
	},
	{
		path: componentPagesMenu.charts.subMenu.chartsSparkline.path,
		element: <CHARTS_PAGE.SPARKLINE />,
	},
	{
		path: componentPagesMenu.charts.subMenu.chartsLine.path,
		element: <CHARTS_PAGE.LINE />,
	},
	{
		path: componentPagesMenu.charts.subMenu.chartsArea.path,
		element: <CHARTS_PAGE.AREA />,
	},
	{
		path: componentPagesMenu.charts.subMenu.chartsColumn.path,
		element: <CHARTS_PAGE.COLUMN />,
	},
	{
		path: componentPagesMenu.charts.subMenu.chartsBar.path,
		element: <CHARTS_PAGE.BAR />,
	},
	{
		path: componentPagesMenu.charts.subMenu.chartsMixed.path,
		element: <CHARTS_PAGE.MIXED />,
	},
	{
		path: componentPagesMenu.charts.subMenu.chartsTimeline.path,
		element: <CHARTS_PAGE.TIMELINE />,
	},
	{
		path: componentPagesMenu.charts.subMenu.chartsCandleStick.path,
		element: <CHARTS_PAGE.CANDLESTICK />,
	},
	{
		path: componentPagesMenu.charts.subMenu.chartsBoxWhisker.path,
		element: <CHARTS_PAGE.BOX_WHISKER />,
	},
	{
		path: componentPagesMenu.charts.subMenu.chartsPieDonut.path,
		element: <CHARTS_PAGE.PIE_DONUT />,
	},
	{
		path: componentPagesMenu.charts.subMenu.chartsRadar.path,
		element: <CHARTS_PAGE.RADAR />,
	},
	{
		path: componentPagesMenu.charts.subMenu.chartsPolar.path,
		element: <CHARTS_PAGE.POLAR />,
	},
	{
		path: componentPagesMenu.charts.subMenu.chartsRadialBar.path,
		element: <CHARTS_PAGE.RADIAL_BAR />,
	},
	{
		path: componentPagesMenu.charts.subMenu.chartsBubble.path,
		element: <CHARTS_PAGE.BUBBLE />,
	},
	{
		path: componentPagesMenu.charts.subMenu.chartsScatter.path,
		element: <CHARTS_PAGE.SCATTER />,
	},
	{
		path: componentPagesMenu.charts.subMenu.chartsHeatMap.path,
		element: <CHARTS_PAGE.HEAT_MAP />,
	},
	{
		path: componentPagesMenu.charts.subMenu.chartsTreeMap.path,
		element: <CHARTS_PAGE.TREE_MAP />,
	},

	{
		path: componentPagesMenu.notification.path,
		element: <EXTRA.NOTIFICATION />,
	},
	{
		path: componentPagesMenu.hooks.path,
		element: <EXTRA.HOOKS />,
	},
];

const contentsMenu = [...screenMenus, ...presentation];

const commonContents = [...commonMenus]


const contentRoutes = () => {

	return {
		contentsMenu, commonContents

	}
};



export default contentRoutes;
