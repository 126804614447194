import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getProfileForStudent = (studentDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentProfile/${getLicenseKey}/${studentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentProfile - " + error.message)
            console.log('error caught in service : getStudentProfile')
        },
    );

export const getStudentProfile = (studentDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentProfile/${getLicenseKey}/${studentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentProfile - " + error.message)
            console.log('error caught in service : getStudentProfile')
        },
    );
export const getStudentDetailsBySectionDetailsId = (sectionDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentDetailsBySectionDetailsId/${getLicenseKey}/${sectionDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentDetailsBySectionDetailsId - " + error.message)
            console.log('error caught in service : getStudentDetailsBySectionDetailsId')
        },
    );

export const getAllStudent = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentDetails/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentDetails - " + error.message)
            console.log('error caught in service : getStudentDetails')
        },
    );

export const addTempStudentDetails = (tempStudentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addTempStudentDetails`, tempStudentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addTempStudentDetails')
        }
    );

export const getTempStudentList = (tempStudentDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getTempStudentList/${getLicenseKey}/${tempStudentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getTempStudentList - " + error.message)
            console.log('error caught in service : getTempStudentList')
        },
    );

export const updateTempStudentDetails = (tempStudentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateTempStudentDetails`, tempStudentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateTempStudentDetails')
        }
    );

export const deleteTempStudentDetails = (tempStudentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteTempStudentDetails`, tempStudentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteTempStudentDetails')
        }
    );

export const addStudentDetails = (studentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentDetails`, studentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentDetails')
        }
    );

export const getStudentTempListByStandardDetailsId = (mediumId: number, standardDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentTempListByStandardDetailsId/${getLicenseKey}/${mediumId}/${standardDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentTempListByStandardDetailsId - " + error.message)
            console.log('error caught in service : getStudentTempListByStandardDetailsId')
        },
    );

export const checkClassCapacity = (courseCapacityStudentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `checkClassCapacity`, courseCapacityStudentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : checkClassCapacity')
        }
    );

export const deleteStudentDetails = (deleteStudentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteStudentDetails`, deleteStudentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteStudentDetails')
        }
    );
export const getStudentTransportDetails = (studentDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentTransportDetails/${getLicenseKey}/${studentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentTransportDetails - " + error.message)
            console.log('error caught in service : getStudentTransportDetails')
        },
    );

export const addStudentTransportDetails = (addStudentTransportDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentTransportDetails`, addStudentTransportDetailsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentTransportDetails')
        }
    );
export const getStudentListByGenderId = (genderId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentListByGenderId/${getLicenseKey}/${genderId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentListByGenderId - " + error.message)
            console.log('error caught in service : getStudentListByGenderId')
        },
    );

export const getDiscontinuedStudentList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getDiscontinuedStudentList/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getDiscontinuedStudentList - " + error.message)
            console.log('error caught in service : getDiscontinuedStudentList')
        },
    );

export const rejoinStudentDetails = (rejoinedStudentFormPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `rejoinStudentDetails`, rejoinedStudentFormPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : rejoinStudentDetails')
        }
    );

export const getAlumniStudentDetails = (batchMasterId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getAlumniStudentDetails/${getLicenseKey}/${batchMasterId}`,
        response => {
            success(response)
        },
        error => {
            failure("getAlumniStudentDetails - " + error.message)
            console.log('error caught in service : getAlumniStudentDetails')
        },
    );

export const updateRollNumberForStudent = (editRollNumberPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateRollNumberForStudent`, editRollNumberPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateRollNumberForStudent')
        }
    );

export const updateStudentDetails = (updateStudentDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `updateStudentDetails`, updateStudentDetailsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStudentDetails')
        }
    );

export const getStudentDetailsByStudentDetailsId = (studentDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentDetailsByStudentDetailsId/${getLicenseKey}/${studentDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentDetailsByStudentDetailsId - " + error.message)
            console.log('error caught in service : getStudentDetailsByStudentDetailsId')
        },
    );

export const updateStudentStandard = (updateStudentStandardPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateStudentStandard`, updateStudentStandardPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStudentStandard')
        }
    );