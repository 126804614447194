import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import HumansWebp from '../../../assets/img/scene2.webp';
import Humans from '../../../assets/img/scene2.webp';
import AuthContext from '../../../contexts/authContext';
import useDarkMode from '../../../hooks/useDarkMode';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { getCurrentDateAndTime, convertDateToEpoch } from '../../../services/common.service';
import { getScheduleDetailsByDate } from '../../../services/timeTableService';
import { toasts } from '../../../services/toast.service';
import Card, { CardLabel, CardTitle, CardBody, CardHeader, CardActions } from '../../bootstrap/Card';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import Button from '../../bootstrap/Button';
import Collapse from '../../bootstrap/Collapse';
import FormGroup from '../../bootstrap/forms/FormGroup';
import { useFormik } from 'formik';
import Input from '../../bootstrap/forms/Input';
import SearchableSelect from '../../../common/components/SearchableSelect';
import Icon from '../../icon/Icon';

const StudentSchedule = () => {

    const { userAccountId, userTypeId } = useContext(AuthContext);

    useEffect(() => {
        getScheduleDetailsList();
    }, []);

    const { themeStatus, darkModeStatus } = useDarkMode();

    const [mediumId, setMediumId] = useState<any>('');
    const [standardDetailsId, setStandardId] = useState<any>('');
    const [sectionDetailsId, setSectionDetailsId] = useState<any>(Number);
    const [dayOrderTypeId, setDayOrderTypeId] = useState<any>('');

    const [scheduleData, setScheduleData] = useState<any>([]);
    const [mediumData, setMediumData] = useState<any>([]);
    const [standardData, setStandardData] = useState<any>([]);
    const [sectionDetailsData, setSectionDetailsData] = useState([]);
    const [dayOrderData, setDayOrderData] = useState<any>([]);
    const [timeTableAllocationData, setTimeTableAllocationData] = useState<any>([]);

    const [isOpenListCard, setIsOpenListCard] = useState(true);
    const [isLoader, setIsLoader] = useState(false);
    const [dataSuccess, setDataSuccess] = useState(false);

    const staffScheduleForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            date: getCurrentDateAndTime('date'),
        },
        validate: (values) => {
            const errors: {
                date?: string;
            } = {};

            if (!values.date) {
                errors.date = 'Required';
            }
            return errors;
        },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    function ViewSchedule() {
        setDayOrder('')
        setDayId('')
        setDataSuccess(false);
        setIsLoader(true);
        setScheduleData([]);
        getScheduleDetailsList();
    }

    const [dayOrder, setDayOrder] = useState<any>('')
    const [dayId, setDayId] = useState<any>('')

    function getScheduleDetailsList() {
        let scheduleDate = staffScheduleForm.values.date;
        let dateInMilliSec = convertDateToEpoch(scheduleDate);

        getScheduleDetailsByDate(
            userTypeId,
            userAccountId,
            dateInMilliSec,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.scheduleDetailsByDate[0].period;
                    if (data != undefined) {
                        setIsLoader(false);
                        setScheduleData(data);
                        setDayId(response.data.data.scheduleDetailsByDate[0].dayId);
                        setDayOrder(response.data.data.scheduleDetailsByDate[0].dayOrderName);
                        setDataSuccess(true);
                        for (let i = 0; i < data.length; i++) {
                            data[i].periodCount = data[i].periodName[data[i].periodName.length - 1];
                        }
                    } else {
                        setIsLoader(false);
                        setDataSuccess(false);
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setIsLoader(false);
                    setIsOpenListCard(false);
                    setDataSuccess(true);
                    // toasts(response.data.message, "Error")
                    setScheduleData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                setIsLoader(false);
                toasts(error, 'Error');
            },
        );
    }

    return (
        <PageWrapper title='Schedule'>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card
                        stretch
                        data-tour='list'
                        tag='form'
                        noValidate
                        onSubmit={staffScheduleForm.handleSubmit}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='Schedule' iconColor='info'>
                                <CardTitle tag='div' className='h5'>
                                    Schedule
                                </CardTitle>
                            </CardLabel>
                            <CardActions>
                                <CardTitle tag='div' className='h5 text-success'>{dataSuccess && userTypeId == 1 ? dayOrderTypeId?.label : dayOrder}</CardTitle>
                            </CardActions>
                        </CardHeader>
                        <CardBody>
                            <div className='row g-4'>
                                <div className='col-md-2'>
                                    <FormGroup id='date' label='Date' isFloating>
                                        <Input
                                            onChange={staffScheduleForm.handleChange}
                                            value={staffScheduleForm.values.date}
                                            onBlur={staffScheduleForm.handleBlur}
                                            isValid={staffScheduleForm.isValid}
                                            isTouched={staffScheduleForm.touched.date}
                                            invalidFeedback={staffScheduleForm.errors.date}
                                            type='date'
                                        />
                                    </FormGroup>
                                </div>

                                <div className='col-md-2'>
                                    <Button
                                        className='mt-2'
                                        icon='ArrowDownward'
                                        color='primary'
                                        onClick={ViewSchedule}>
                                        View
                                    </Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent
                    isOpenListCard={isOpenListCard}
                    setIsOpenListCard={setIsOpenListCard}
                    isLoader={isLoader}
                />
                {dataSuccess ? (
                    <>
                        {scheduleData != '' && dayId > 0 && dayId != 7 ? (
                            <>
                                <div className='col-12'>
                                    <div className='row'>
                                        {scheduleData.map((period: any) => (
                                            <div className='col-3' key={period.periodDetailsId}>
                                                <Card>
                                                    <CardBody>
                                                        <div className='row g-3 align-items-center'>
                                                            <div className='col d-flex align-items-center'>
                                                                <div className='flex-shrink-0'>
                                                                    <div
                                                                        className='ratio ratio-1x1'
                                                                        style={{ width: 65 }}>
                                                                        <div
                                                                            className={classNames(
                                                                                'rounded-2',
                                                                                'd-flex align-items-center justify-content-center',
                                                                                {
                                                                                    'bg-l10-info':
                                                                                        !darkModeStatus,
                                                                                    'bg-lo25-info':
                                                                                        darkModeStatus,
                                                                                },
                                                                            )}>
                                                                            <span className='fw-bold'>
                                                                                <span className='ms-1 text-info fs-2'>
                                                                                    {period.periodCount}
                                                                                </span><br />
                                                                                {userTypeId == 1 ?
                                                                                    <small className='text-dark fs-6'>
                                                                                        {standardDetailsId?.label} - {sectionDetailsId?.label}
                                                                                    </small> : null
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
                                                                    <div>
                                                                        <small className='fw-bold'>
                                                                            {period.paperName}
                                                                        </small>
                                                                        <div className=' text-info fs-6 mb-0'>
                                                                            {period.staffName}
                                                                        </div>
                                                                        <div className='text-info'>
                                                                            <small>
                                                                                <span className='text-black-50 fw-bold'>
                                                                                    {period.startTime}-{period.endTime}
                                                                                </span>
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        ))}{' '}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className='row'>
                                <div className='col-12 d-flex justify-content-center'>
                                    {scheduleData != '' && (dayId == 0 || dayId == 7) ?
                                        <Icon style={{ height: '30vh' }} icon='CalendarToday' size={'10x'} /> :
                                        <img
                                            srcSet={HumansWebp}
                                            src={Humans}
                                            alt='Humans'
                                            style={{ height: '50vh' }}
                                        />
                                    }
                                </div>
                                <div className='fs-4 fw-bold d-flex justify-content-center text-center text-uppercase text-danger'>
                                    {scheduleData != '' && (dayId == 0 || dayId == 7) ? 'Holiday' : 'Schedule Not Allocated'}
                                </div>
                            </div>
                        )}
                    </>
                ) : null}
            </Page>
        </PageWrapper>
    );
};

export default StudentSchedule;
