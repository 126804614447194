import classNames from "classnames";
import { useFormik } from "formik";
import { useEffect, useState, useRef, useContext } from "react";
import { useReactToPrint } from "react-to-print";
import useDarkMode from "../../../../hooks/useDarkMode";
import useSortableData from "../../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../../PaginationButtons";
import Button, { ButtonGroup } from "../../../bootstrap/Button";
import { toasts } from "../../../../services/toast.service";
import Page from "../../../../layout/Page/Page";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../../bootstrap/Card";
import Icon from "../../../icon/Icon";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../../bootstrap/Dropdown";
import Input from "../../../bootstrap/forms/Input";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import Checks, { ChecksGroup } from "../../../bootstrap/forms/Checks";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../../services/ExportService";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../bootstrap/Modal";
import Select from "../../../bootstrap/forms/Select";
import Option from "../../../bootstrap/Option";
import AlertService from "../../../../services/AlertService";
import DeleteComponents from "../../../../common/components/DeleteComponents";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import { getCity, getColumnsForDataTable, getGenderDetails, getMedium, getStandardDetails, getStates, onlyAllowNumber, updateColumnsForDataTable, updateFilter } from "../../../../services/common.service";
import { getLicenseKey } from "../../../../services/application.settings";
import AuthContext from "../../../../contexts/authContext";
import { deleteTempStudentDetails, getTempStudentList, updateTempStudentDetails } from "../../../../services/student.service";
import { TableLoader, showLoader } from "../../../../services/loader.services";
import NoDataMsg from "../../../../common/components/NoDataMsg";
import SearchableSelect from "../../../../common/components/SearchableSelect";

const TempStudentList = () => {

    useEffect(() => {
        getStandardList();
        getMediumList();
        getGender();
        getStatesList();
        getTempStudent();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [tempStudentData, setTempStudentData] = useState([]);
    const [departmentData, setDepartmentData] = useState<any>([])
    const [genderData, setGenderData] = useState<any>([])
    const [graduationTypeData, setGraduationTypeData] = useState<any>([])
    const [courseData, setCourseData] = useState<any>([])
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const [dataStatus, setDataStatus] = useState(false)

    // ForModal And Tables States
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(tempStudentData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [editCourseModal, setEditCourseModal] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const [statesData, setStatesData] = useState<any>([])
    const [cityData, setCityData] = useState<any>([])
    const [studentSchoolNameData, setStudentSchoolNameData] = useState<any>([])
    const [studentCollegeNameData, setStudentCollegeNameData] = useState<any>([])

    const [showCollegeInfo, setShowCollegeInfo] = useState(false)
    const [departmentId, setDepartmentId] = useState<any>('')
    const [genderId, setGenderId] = useState<any>('')
    const [courseId, setCourseId] = useState<any>('')
    const [graduationTypeId, setGraduationTypeId] = useState<any>('')
    const [stateId, setStateId] = useState<any>('')
    const [cityId, setCityId] = useState<any>('')
    const [schoolNameId, setSchoolNameId] = useState<any>('')
    const [collegeNameId, setCollegeNameId] = useState<any>('')


    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('')

    const [standardDetailsId, setStandardDetailsId] = useState<any>('')
    const [mediumId, setMediumId] = useState<any>('')
    const [standardData, setStandardData] = useState<any>([])
    const [mediumData, setMediumData] = useState<any>([])

    const editTempStudentForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            tempStudentDetailsId: '',
            tempNo: '',
            firstname: '',
            lastname: '',
            genderId: '',
            mobile: '',
            mediumId: '',
            standardDetailsId: '',
            commAddr_FlatNo: '',
            commAddr_Street: '',
            commAddr_Area: '',
            commAddr_StateId: '',
            commAddr_DistrictId: '',
            commAddr_pincode: '',
        },
        validate: (values: any) => {
            const errors: {
                firstname?: string;
                genderId?: any;
                mobile?: string;
                standardDetailsId?: any;
                mediumId?: any;
                commAddr_FlatNo?: string;
                commAddr_Street?: string;
                commAddr_Area?: string;
                commAddr_StateId?: any;
                commAddr_DistrictId?: any;
                commAddr_pincode?: string;
            } = {};
            if (!values.firstname) {
                errors.firstname = 'Required';
            }
            if (!values.genderId) {
                errors.genderId = 'Required';
            }
            if (!standardDetailsId) {
                errors.standardDetailsId = 'Required';
            }
            if (!values.mediumId) {
                errors.mediumId = 'Required';
            }
            if (!values.mobile) {
                errors.mobile = 'Required';
            } else if (values.mobile.length < 10 || values.mobile.length > 10) {
                errors.mobile = 'Must be 10 Numbers'
            } else if (!/^[6-9]\d{9}$/i.test(values.mobile)) {
                errors.mobile = 'Please enter valid mobile number'
            }
            if (!values.commAddr_Street) {
                errors.commAddr_Street = 'Required';
            }
            if (!values.commAddr_FlatNo) {
                errors.commAddr_FlatNo = 'Required';
            }
            if (!values.commAddr_Area) {
                errors.commAddr_Area = 'Required';
            }
            if (!stateId) {
                errors.commAddr_StateId = 'Required';
            }
            if (!cityId) {
                errors.commAddr_DistrictId = 'Required';
            }
            if (!values.commAddr_pincode) {
                errors.commAddr_pincode = 'Please provide a valid zip.';
            } else if (!/^[0-9]{6}/i.test(values.commAddr_pincode)) {
                errors.commAddr_pincode = 'Must be 6 characters';
            }
            return errors;
        },
        validateOnChange: false,
        onSubmit: (values) => { updateTempStudentSubmit() },
    });

    const deleteTempStudentForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            tempStudentDetailsId: '',
        },
        validate: (values) => {
        },

        validateOnChange: false,
        onSubmit: () => { },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        validateOnChange: false,
        onSubmit: () => { },
    });

    const selectGender = (e: any) => {
        let genderId = e
        setGenderId(genderId)
    }

    const selectMedium = (e: any) => {
        let mediumId = e
        setMediumId(mediumId)
    }

    const selectStandard = (e: any) => {
        let standardDetailsId = e
        setStandardDetailsId(standardDetailsId)
    }

    const selectState = (e: any) => {
        setCityId('')
        setCityData([])
        let commAddr_StateId = e
        setStateId(commAddr_StateId)
        if (commAddr_StateId?.value != undefined) {
            getCityList(commAddr_StateId?.value)
        }
    }

    const selectCity = (e: any) => {
        let commAddr_DistrictId = e
        setCityId(commAddr_DistrictId)
    }

    function onActivate(i: any) {
        if (i != undefined) {

            let selectedTempStudent = i
            setEditCourseModal(true)

            if (selectedTempStudent.commAddr_StateId > 0) {
                getCityList(selectedTempStudent.commAddr_StateId != null ? selectedTempStudent.commAddr_StateId : 0)
            }
            setMediumId(selectedTempStudent.mediumId != null ? { value: selectedTempStudent.mediumId, label: selectedTempStudent.medium } : 0)
            setStandardDetailsId(selectedTempStudent.standardDetailsId != null ? { value: selectedTempStudent.standardDetailsId, label: selectedTempStudent.standardName } : 0)
            setStateId(selectedTempStudent.commAddr_StateId != null ? { value: selectedTempStudent.commAddr_StateId, label: selectedTempStudent.stateName } : 0)
            setCityId(selectedTempStudent.commAddr_CityId != null ? { value: selectedTempStudent.commAddr_CityId, label: selectedTempStudent.cityName } : 0)


            setGenderId(selectedTempStudent.genderId != null ? { value: selectedTempStudent.genderId, label: selectedTempStudent.gender } : 0)

            editTempStudentForm.setValues({
                tempStudentDetailsId: selectedTempStudent.tempStudentDetailsId,
                tempNo: selectedTempStudent.tempNo != null ? selectedTempStudent.tempNo : '',
                firstname: selectedTempStudent.firstname != null ? selectedTempStudent.firstname : '',
                lastname: selectedTempStudent.lastname != null ? selectedTempStudent.lastname : '',
                genderId: selectedTempStudent.genderId != null ? selectedTempStudent.genderId : 0,
                mobile: selectedTempStudent.mobile != null ? selectedTempStudent.mobile : '',
                standardDetailsId: selectedTempStudent.standardDetailsId != null ? selectedTempStudent.standardDetailsId : 0,
                mediumId: selectedTempStudent.mediumId != null ? selectedTempStudent.mediumId : 0,
                commAddr_FlatNo: selectedTempStudent.commAddr_FlatNo != null ? selectedTempStudent.commAddr_FlatNo : '',
                commAddr_Street: selectedTempStudent.commAddr_Street != null ? selectedTempStudent.commAddr_Street : '',
                commAddr_Area: selectedTempStudent.commAddr_Area != null ? selectedTempStudent.commAddr_Area : '',
                commAddr_StateId: selectedTempStudent.commAddr_StateId != null ? selectedTempStudent.commAddr_StateId : 0,
                commAddr_DistrictId: selectedTempStudent.commAddr_CityId != null ? selectedTempStudent.commAddr_CityId : 0,
                commAddr_pincode: selectedTempStudent.commAddr_Pincode != null ? selectedTempStudent.commAddr_Pincode : '',
            });

            deleteTempStudentForm.setValues({
                tempStudentDetailsId: selectedTempStudent.tempStudentDetailsId,
            })
        }
    }

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function getTempStudent() {
        setDataStatus(false)
        getTempStudentList(0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.tempStudentList;
                    if (data != undefined) {
                        getColumnsForTable('getTempStudentList', 'get')
                        setTempStudentData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        setDataStatus(true)
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true)
                    getColumnsForTable('getTempStudentList', 'get')
                    setTempStudentData([]);
                    setNoDataMsg(response.data.message)
                    //toasts(response.data.message, "Error")
                } else {
                    setDataStatus(true)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function setEditTempStudentDetails() {
        return ({
            tempStudentDetailsId: editTempStudentForm.values.tempStudentDetailsId,
            tempNo: editTempStudentForm.values.tempNo,
            firstname: editTempStudentForm.values.firstname,
            lastname: editTempStudentForm.values.lastname,
            genderId: genderId?.value,
            mobile: editTempStudentForm.values.mobile,
            standardDetailsId: standardDetailsId?.value,
            mediumId: mediumId?.value,
            commAddr_FlatNo: editTempStudentForm.values.commAddr_FlatNo,
            commAddr_Street: editTempStudentForm.values.commAddr_Street,
            commAddr_Area: editTempStudentForm.values.commAddr_Area,
            commAddr_DistrictId: cityId?.value,
            commAddr_StateId: stateId?.value,
            commAddr_pincode: editTempStudentForm.values.commAddr_pincode,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
            isActive: 1
        })
    }

    function updateTempStudentSubmit() {
        showLoader(true)
        if (editTempStudentForm.isValid) {
            let tempStudentPostData = setEditTempStudentDetails()
            updateTempStudentDetails(tempStudentPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeModal();
                        getTempStudent();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (editTempStudentForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteTempStudentSubmit() {
        showLoader(true);
        // if (editTempStudentForm.isValid) {
        let tempStudentPostData = setEditTempStudentDetails()
        deleteTempStudentDetails(tempStudentPostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false);
                    setAlertStatus({ message: data.message, type: "success" });
                    setIsOpen(true);
                    closeModal();
                    getTempStudent();
                }
                else if (data.success == false) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "error" });
                    setIsOpen(true);
                }
                else {
                    showLoader(false)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false)
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
        // } else if (editTempStudentForm.isValid == false) {
        //     setAlertStatus({ message: 'Please fill all the details!', type: "error" });
        //     setIsOpen(true);
        // }
    }

    function getStandardList() {
        getStandardDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.standardDetails;
                    if (data != undefined) {
                        setStandardData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStandardData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getMediumList() {
        getMedium(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.medium;
                    if (data != undefined) {
                        setMediumData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setMediumData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGender() {
        getGenderDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.genderDetails;
                    if (data != undefined) {
                        setGenderData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGenderData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStatesList() {
        getStates(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity;
                    if (data != undefined) {
                        setStatesData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStatesData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCityList(stateId: number) {
        getCity(stateId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity[0]['city'];
                    if (data != undefined) {
                        setCityData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCityData([]);
                } else {

                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getTempStudentList', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeModal() {
        setMediumId('')
        setStandardDetailsId('')
        editTempStudentForm.resetForm();
        setEditCourseModal(false);
        setIsDeleteOpen(false);
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <PageWrapper title="Temp Student List">
            <Page container='fluid'>
                <Card stretch data-tour='list' ref={componentRef}>
                    <CardHeader borderSize={1}>
                        <CardLabel icon='List' iconColor='info' className="col-lg-5">
                            <CardTitle tag='div' className='h5'>Temp Student List</CardTitle>
                        </CardLabel>&nbsp;&nbsp;

                        <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                            <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                            <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                        </ButtonGroup>

                        <CardActions className="d-print-none">
                            <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                <DropdownToggle>
                                    <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                </DropdownToggle>
                                <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                    <DropdownItem>
                                        <div className='container py-2'>
                                            <form className='row g-3'>
                                                <div className='col-12'>
                                                    <FormGroup>
                                                        <h6>Select All</h6>
                                                        <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getTempStudentList', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                    </FormGroup>
                                                </div>
                                                <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                    <FormGroup>
                                                        <h6>Columns</h6>
                                                        <ChecksGroup>
                                                            {allColumnsData.map((i: any) => (
                                                                <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                            ))}
                                                        </ChecksGroup>
                                                    </FormGroup>
                                                </div>
                                            </form>
                                        </div>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <Dropdown isButtonGroup>
                                <DropdownToggle>
                                    <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                </DropdownToggle>
                                <DropdownMenu isAlignmentEnd>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("Temp Student List", tempStudentData, columnVisibilityData)}> Excel </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(tempStudentData, columnVisibilityData, "Temp Student List")}> PDF
                                        </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("Temp Student List", tempStudentData, columnVisibilityData)}> CSV </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            {userTypeId == 2 || userTypeId == 3 || userTypeId == 4 || userTypeId == 5 ?
                                null :
                                <Button color='primary' isLight icon='Add' tag='a'
                                    to={`../academic/addTempStudentDetails`} >
                                    Add New
                                </Button>}
                        </CardActions>
                    </CardHeader>

                    <CardBody className='table-responsive' isScrollable>
                        {!dataStatus ?
                            <TableLoader /> :
                            <table className='table table-modern table-hover text-nowrap'>
                                <thead>
                                    <tr>
                                        <th scope='col' onClick={() => requestSort('sno')}
                                            className='cursor-pointer text-decoration-underline'>S.No{' '}
                                            <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                        </th>
                                        {columnVisibilityData.map((column: any) => (
                                            <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                className='cursor-pointer text-decoration-underline'>
                                                {column.columnName}
                                                <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                            </th>
                                        ))}
                                        {userTypeId == 2 || userTypeId == 3 || userTypeId == 4 || userTypeId == 5 ?
                                            null :
                                            <th scope='col' className='d-print-none'>
                                                Action
                                            </th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData != "" ?
                                        <>
                                            {filteredData.map((rowData: any) => (<tr key={rowData.tempStudentDetailsId}><td>{rowData.sno}</td>{columnVisibilityData.map((column: any) => ((column.isDisplay && rowData[column.keyName]) ?
                                                <td key={`${rowData.tempStudentDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}
                                                {userTypeId == 2 || userTypeId == 3 || userTypeId == 4 || userTypeId == 5 ?
                                                    null :
                                                    <td className="d-print-none">
                                                        <Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} /></td>}
                                            </tr>))}</>
                                        :
                                        <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                </tbody>
                            </table>
                        }
                    </CardBody>
                    <PaginationButtons
                        className="d-print-none"
                        data={items}
                        label='items'
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        perPage={perPage}
                        setPerPage={setPerPage}
                    />
                </Card>
            </Page>

            <Modal
                setIsOpen={setEditCourseModal}
                isOpen={editCourseModal}
                titleId='addTempStudent'
                isCentered
                isStaticBackdrop
                size='xl'>
                <ModalHeader className="'modal-header'"
                    setIsOpen={() => { setEditCourseModal(false) }} onClick={closeModal}>
                    <ModalTitle id='addTempStudent'>Update Student Details</ModalTitle>
                </ModalHeader>
                <form noValidate onSubmit={editTempStudentForm.handleSubmit}>
                    <ModalBody className='px-4'>
                        <div className='row g-4'>
                            <div className="col-md-12">
                                <CardLabel icon='AccountBox'>
                                    <CardTitle>Basic Info</CardTitle>
                                </CardLabel>
                            </div>
                            <div className='col-md-3'>
                                <FormGroup id='tempNo' label='Temp No' isFloating>
                                    <Input
                                        placeholder='Temp No'
                                        onChange={editTempStudentForm.handleChange}
                                        value={editTempStudentForm.values.tempNo}
                                        isValid={editTempStudentForm.isValid}
                                        isTouched={editTempStudentForm.touched.tempNo}
                                        invalidFeedback={editTempStudentForm.errors.tempNo}
                                        disabled
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-md-3'>
                                <FormGroup id='firstname' label='First Name' isFloating>
                                    <Input
                                        placeholder='First Name'
                                        onChange={editTempStudentForm.handleChange}
                                        value={editTempStudentForm.values.firstname}
                                        isValid={editTempStudentForm.isValid}
                                        isTouched={editTempStudentForm.touched.firstname}
                                        invalidFeedback={editTempStudentForm.errors.firstname}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-md-3'>
                                <FormGroup id='lastname' label='Last Name' isFloating>
                                    <Input
                                        placeholder='Last Name'
                                        onChange={editTempStudentForm.handleChange}
                                        value={editTempStudentForm.values.lastname}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-md-3'>
                                <FormGroup id='genderId' label='Gender' isFloating>
                                    <SearchableSelect isFloating
                                        ariaLabel='Gender'
                                        onChange={selectGender}
                                        onBlur={editTempStudentForm.handleBlur}
                                        value={genderId}
                                        isValid={editTempStudentForm.isValid}
                                        isTouched={editTempStudentForm.touched.genderId}
                                        invalidFeedback={editTempStudentForm.errors.genderId}
                                        list={genderData.map((data: any) => (
                                            { value: data.genderId, label: data.gender }
                                        ))}
                                    />
                                </FormGroup>
                            </div>


                            <div className='col-md-3'>
                                <FormGroup id='mediumId' label='Medium' isFloating>
                                    <SearchableSelect isFloating ariaLabel='Select Medium' placeholder="Medium"
                                        onChange={(e: any) => selectMedium(e)}
                                        onBlur={editTempStudentForm.handleBlur}
                                        value={mediumId}
                                        isValid={editTempStudentForm.isValid}
                                        isTouched={editTempStudentForm.touched.mediumId}
                                        invalidFeedback={editTempStudentForm.errors.mediumId}
                                        list={mediumData.map((data: any) => ({ value: data.mediumId, label: data.medium }))} required />

                                </FormGroup>
                            </div>

                            <div className='col-md-3'>
                                <FormGroup id='standardDetailsId' label='Standard' isFloating>
                                    <SearchableSelect isFloating ariaLabel='Standard' placeholder="Standard"
                                        onChange={(e: any) => selectStandard(e)}
                                        onBlur={editTempStudentForm.handleBlur}
                                        value={standardDetailsId}
                                        isValid={editTempStudentForm.isValid}
                                        isTouched={editTempStudentForm.touched.standardDetailsId}
                                        invalidFeedback={editTempStudentForm.errors.standardDetailsId}
                                        list={standardData.map((data: any) => ({ value: data.standardDetailsId, label: data.standardName }))} required />

                                </FormGroup>
                            </div>

                            <div className='col-md-3'>
                                <FormGroup id='mobile' label='Mobile/ SMS Number ' isFloating>
                                    <Input
                                        placeholder='Mobile No'
                                        onKeyDown={(e: any) => onlyAllowNumber(e)}
                                        onChange={editTempStudentForm.handleChange}
                                        onBlur={editTempStudentForm.handleBlur}
                                        value={editTempStudentForm.values.mobile}
                                        isValid={editTempStudentForm.isValid}
                                        isTouched={editTempStudentForm.touched.mobile}
                                        invalidFeedback={editTempStudentForm.errors.mobile}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-md-12">
                                <CardLabel icon='ReceiptLong'>
                                    <CardTitle>Address Info</CardTitle>
                                </CardLabel>
                            </div>
                            <div className='col-md-3'>
                                <FormGroup id='commAddr_FlatNo' label='Flat No\Door No' isFloating>
                                    <Input
                                        placeholder='Flat No\Door No'
                                        onChange={editTempStudentForm.handleChange}
                                        value={editTempStudentForm.values.commAddr_FlatNo}
                                        onBlur={editTempStudentForm.handleBlur}
                                        isValid={editTempStudentForm.isValid}
                                        isTouched={editTempStudentForm.touched.commAddr_FlatNo}
                                        invalidFeedback={editTempStudentForm.errors.commAddr_FlatNo}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-md-3'>
                                <FormGroup id='commAddr_Street' label='Street' isFloating>
                                    <Input
                                        placeholder='Street'
                                        onChange={editTempStudentForm.handleChange}
                                        value={editTempStudentForm.values.commAddr_Street}
                                        onBlur={editTempStudentForm.handleBlur}
                                        isValid={editTempStudentForm.isValid}
                                        isTouched={editTempStudentForm.touched.commAddr_Street}
                                        invalidFeedback={editTempStudentForm.errors.commAddr_Street}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-md-3'>
                                <FormGroup id='commAddr_Area' label='Area' isFloating>
                                    <Input
                                        placeholder='Area'
                                        onChange={editTempStudentForm.handleChange}
                                        value={editTempStudentForm.values.commAddr_Area}
                                        onBlur={editTempStudentForm.handleBlur}
                                        isValid={editTempStudentForm.isValid}
                                        isTouched={editTempStudentForm.touched.commAddr_Area}
                                        invalidFeedback={editTempStudentForm.errors.commAddr_Area}
                                    />
                                </FormGroup>
                            </div>

                            <div className='col-md-3'>
                                <FormGroup id='commAddr_StateId' label='State' isFloating>
                                    <SearchableSelect isFloating
                                        ariaLabel='State'
                                        onChange={selectState}
                                        onBlur={editTempStudentForm.handleBlur}
                                        isValid={editTempStudentForm.isValid}
                                        isTouched={editTempStudentForm.touched.commAddr_StateId}
                                        invalidFeedback={editTempStudentForm.errors.commAddr_StateId}
                                        value={stateId}
                                        list={statesData.map((data: any) => (
                                            { value: data.stateId, label: data.stateName }
                                        ))}
                                        required />
                                </FormGroup>
                            </div>

                            <div className='col-md-3'>
                                <FormGroup id='commAddr_DistrictId' label='City' isFloating>
                                    <SearchableSelect isFloating
                                        ariaLabel='City'
                                        onChange={selectCity}
                                        value={cityId}
                                        onBlur={editTempStudentForm.handleBlur}
                                        isValid={editTempStudentForm.isValid}
                                        isTouched={editTempStudentForm.touched.commAddr_DistrictId}
                                        invalidFeedback={editTempStudentForm.errors.commAddr_DistrictId}
                                        list={cityData.map((data: any) => (
                                            { value: data.cityId, label: data.cityName }
                                        ))}
                                        required />
                                </FormGroup>
                            </div>

                            <div className='col-md-3'>
                                <FormGroup id='commAddr_pincode' label='PinCode' isFloating>
                                    <Input
                                        placeholder='PinCode'
                                        onChange={editTempStudentForm.handleChange}
                                        value={editTempStudentForm.values.commAddr_pincode}
                                        onBlur={editTempStudentForm.handleBlur}
                                        isValid={editTempStudentForm.isValid}
                                        isTouched={editTempStudentForm.touched.commAddr_pincode}
                                        invalidFeedback={editTempStudentForm.errors.commAddr_pincode}
                                        onKeyDown={(e: any) => onlyAllowNumber(e)}
                                    />
                                </FormGroup>
                            </div>
                            {/* <div className='col-md-6'>
                                <Card className='rounded-1 mb-2'>
                                    <CardHeader>
                                        <CardLabel icon='ReceiptLong'>
                                            <CardTitle>Address Info</CardTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="row g-4">
                                            <div className='col-6'>
                                                <FormGroup id='commAddr_FlatNo' label='Flat No\Door No' isFloating>
                                                    <Input
                                                        placeholder='Flat No\Door No'
                                                        onChange={editTempStudentForm.handleChange}
                                                        value={editTempStudentForm.values.commAddr_FlatNo}
                                                        onBlur={editTempStudentForm.handleBlur}
                                                        isValid={editTempStudentForm.isValid}
                                                        isTouched={editTempStudentForm.touched.commAddr_FlatNo}
                                                        invalidFeedback={editTempStudentForm.errors.commAddr_FlatNo}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className='col-6'>
                                                <FormGroup id='commAddr_Street' label='Street' isFloating>
                                                    <Input
                                                        placeholder='Street'
                                                        onChange={editTempStudentForm.handleChange}
                                                        value={editTempStudentForm.values.commAddr_Street}
                                                        onBlur={editTempStudentForm.handleBlur}
                                                        isValid={editTempStudentForm.isValid}
                                                        isTouched={editTempStudentForm.touched.commAddr_Street}
                                                        invalidFeedback={editTempStudentForm.errors.commAddr_Street}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className='col-6'>
                                                <FormGroup id='commAddr_Area' label='Area' isFloating>
                                                    <Input
                                                        placeholder='Area'
                                                        onChange={editTempStudentForm.handleChange}
                                                        value={editTempStudentForm.values.commAddr_Area}
                                                        onBlur={editTempStudentForm.handleBlur}
                                                        isValid={editTempStudentForm.isValid}
                                                        isTouched={editTempStudentForm.touched.commAddr_Area}
                                                        invalidFeedback={editTempStudentForm.errors.commAddr_Area}
                                                    />
                                                </FormGroup>
                                            </div>

                                            <div className='col-6'>
                                                <FormGroup id='commAddr_StateId' label='State' isFloating>
                                                    <SearchableSelect isFloating
                                                        ariaLabel='State'
                                                        onChange={selectState}
                                                        onBlur={editTempStudentForm.handleBlur}
                                                        isValid={editTempStudentForm.isValid}
                                                        isTouched={editTempStudentForm.touched.commAddr_StateId}
                                                        invalidFeedback={editTempStudentForm.errors.commAddr_StateId}
                                                        value={stateId}
                                                        list={statesData.map((data: any) => (
                                                            { value: data.stateId, label: data.stateName }
                                                        ))}
                                                        required />
                                                </FormGroup>
                                            </div>

                                            <div className='col-6'>
                                                <FormGroup id='commAddr_DistrictId' label='City' isFloating>
                                                    <SearchableSelect isFloating
                                                        ariaLabel='City'
                                                        onChange={selectCity}
                                                        value={cityId}
                                                        onBlur={editTempStudentForm.handleBlur}
                                                        isValid={editTempStudentForm.isValid}
                                                        isTouched={editTempStudentForm.touched.commAddr_DistrictId}
                                                        invalidFeedback={editTempStudentForm.errors.commAddr_DistrictId}
                                                        list={cityData.map((data: any) => (
                                                            { value: data.cityId, label: data.cityName }
                                                        ))}
                                                        required />
                                                </FormGroup>
                                            </div>

                                            <div className='col-6'>
                                                <FormGroup id='commAddr_pincode' label='PinCode' isFloating>
                                                    <Input
                                                        placeholder='PinCode'
                                                        onChange={editTempStudentForm.handleChange}
                                                        value={editTempStudentForm.values.commAddr_pincode}
                                                        onBlur={editTempStudentForm.handleBlur}
                                                        isValid={editTempStudentForm.isValid}
                                                        isTouched={editTempStudentForm.touched.commAddr_pincode}
                                                        invalidFeedback={editTempStudentForm.errors.commAddr_pincode}
                                                        onKeyDown={(e: any) => onlyAllowNumber(e)}
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div> */}

                            {/* {showCollegeInfo ?
								<div className='col-md-6'>
									<Card className='rounded-1 mb-2' borderSize={2}>
										<CardHeader>
											<CardLabel icon='School'>
												<CardTitle>College Info</CardTitle>
											</CardLabel>
										</CardHeader>
										<CardBody>
											<div className="row g-4">
												<div className='col-6'>
													<FormGroup id='regNumber' label='Register Number' isFloating>
														<Input
															placeholder='Register Number'
															onChange={editTempStudentForm.handleChange}
															value={editTempStudentForm.values.regNumber}
														/>
													</FormGroup>
												</div>
												<div className='col-6'>
													<FormGroup id='scoredMark' label='Scored Mark' isFloating>
														<Input
															placeholder='Scored Mark'
															onKeyDown={(e: any) => onlyAllowNumber(e)}
															onChange={editTempStudentForm.handleChange}
															value={editTempStudentForm.values.scoredMark}
														/>
													</FormGroup>
												</div>
												<div className='col-12'>
													<FormGroup id='govtOrPvt' label='Govt / Pvt' isFloating>
														<Select
															ariaLabel='Govt / Pvt'
															placeholder='Govt / Pvt'
															onChange={editTempStudentForm.handleChange}
															value={editTempStudentForm.values.govtOrPvt}>
															<Option value='Government'>Government</Option>
															<Option value='Private'>Private</Option>
														</Select>
													</FormGroup>
												</div>

												<div className='col-12'>
													<FormGroup id='collegeNameId' label='College Name' isFloating>
														<SearchableSelect isFloating
															ariaLabel='College Name'
															onChange={selectCollege}
															value={collegeNameId}
															list={studentCollegeNameData.map((data: any) => (
																{ value: data.studentCollegeNameId, label: data.collegeName }
															))}
															required />
													</FormGroup>
												</div>

											</div>
										</CardBody>
									</Card>
								</div>
								: <div className='col-md-6'>
									<Card className='rounded-1 mb-2' borderSize={2}>
										<CardHeader>
											<CardLabel icon='School'>
												<CardTitle>School Info</CardTitle>
											</CardLabel>
										</CardHeader>
										<CardBody>
											<div className="row g-4">
												<div className='col-6'>
													<FormGroup id='regNumber' label='Register Number' isFloating>
														<Input
															placeholder='Register Number'

															onChange={editTempStudentForm.handleChange}
															value={editTempStudentForm.values.regNumber}
														/>
													</FormGroup>
												</div>
												<div className='col-6'>
													<FormGroup id='scoredMark' label='Scored Mark' isFloating>
														<Input
															placeholder='Scored Mark'
															onKeyDown={(e: any) => onlyAllowNumber(e)}
															onChange={editTempStudentForm.handleChange}
															value={editTempStudentForm.values.scoredMark}
														/>
													</FormGroup>
												</div>
												<div className='col-12'>
													<FormGroup id='govtOrPvt' label='Govt / Pvt' isFloating>
														<Select
															ariaLabel='Govt / Pvt'
															placeholder='Govt / Pvt'
															onChange={editTempStudentForm.handleChange}
															value={editTempStudentForm.values.govtOrPvt}>
															<Option value='Government'>Government</Option>
															<Option value='Private'>Private</Option>
														</Select>
													</FormGroup>
												</div>

												<div className='col-12'>
													<FormGroup id='schoolNameId' label='School Name' isFloating>
														<SearchableSelect isFloating
															ariaLabel='School Name'
															onChange={selectSchool}
															onBlur={editTempStudentForm.handleBlur}
															value={schoolNameId}
															list={studentSchoolNameData.map((data: any) => (
																{ value: data.studentSchoolNameId, label: data.schoolName }
															))}
															required />
													</FormGroup>
												</div>

											</div>
										</CardBody>
									</Card>
								</div>
							} */}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className='row'>
                            <div className="d-flex justify-content-center position-absolute top-65 start-80 translate-middle">
                                <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteTempStudentSubmit} />
                            </div>
                        </div>
                        <Button color='info' isOutline className='border-0' onClick={closeModal}>Close</Button>
                        <Button color='danger' icon='Delete' onClick={() => setIsDeleteOpen(!isDeleteOpen)}>Delete</Button>
                        <Button color='info' icon='Save' type="submit" isDisable={!editTempStudentForm.isValid && !editTempStudentForm.submitCount}>Update </Button>
                    </ModalFooter>
                </form>
            </Modal>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </PageWrapper>
    )
}
export default TempStudentList;