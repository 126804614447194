import { useContext, useEffect, useState } from "react";
import Page from "../../../layout/Page/Page";
import Collapse from "../../bootstrap/Collapse";
import Icon from "../../icon/Icon";
import { toasts } from "../../../services/toast.service";
import { useFormik } from "formik";
import useDarkMode from "../../../hooks/useDarkMode";
import useSortableData from "../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Input from "../../bootstrap/forms/Input";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import Select from "../../bootstrap/forms/Select";
import AlertService from "../../../services/AlertService";
import OpenCardComponent from "../../../common/components/OpenCardComponent";
import { getSectionDetails } from "../../../services/master.service";
import AuthContext from "../../../contexts/authContext";
import { getLicenseKey } from "../../../services/application.settings";
import SearchableSelect from "../../../common/components/SearchableSelect";
import { showLoader } from "../../../services/loader.services";
import NoDataMsg from "../../../common/components/NoDataMsg";
import { getMedium, getStandardDetails } from "../../../services/common.service";
import { getStudentDetailsBySectionDetailsId, updateRollNumberForStudent } from "../../../services/student.service";

const EditRollNumber = () => {

    useEffect(() => {
        getStandard();
        getMediumList();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [mediumId, setMediumId] = useState<any>(Number)
    const [standardDetailsId, setStandardDetailsId] = useState<any>(Number)
    const [sectionDetailsId, setSectionDetailsId] = useState<any>(Number)

    const [mediumData, setMediumData] = useState<any>([])
    const [standardData, setStandardData] = useState<any>([])
    const [sectionDetailsData, setSectionDetailsData] = useState<any>([])

    const [filteredData1, setFilteredData1] = useState<any>([])
    const [updateStudentRollNo, setUpdateStudentRollNo] = useState<any>([])
    const [isLoader, setIsLoader] = useState(false)

    // For Table
    const [studentDetailsData, setStudentDetailsData] = useState<any>([])
    const [dataSuccess, setDataSuccess] = useState(false)
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(studentDetailsData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [noDataMsg, setNoDataMsg] = useState('')

    const editUniversityNumberForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            studentDetailsId: '',
            studentRollNo: '',
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = items.filter(
        (i) =>
            i.admissionNo.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.studentName.toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase())
    );

    const selectMedium = (e: any) => {
        setStandardDetailsId('')
        setSectionDetailsId('')
        let mediumId = e
        setMediumId(mediumId)
    }

    const selectStandard = (e: any) => {
        setSectionDetailsId('')
        let standardDetailsId = e
        setStandardDetailsId(standardDetailsId)
        if(standardDetailsId?.value != undefined){
            getSectionList(mediumId?.value, standardDetailsId?.value)
        }
    }

    const selectSection = (e: any) => {
        let sectionDetailsId = e
        setSectionDetailsId(sectionDetailsId)
    }

    function viewStudentList() {
        setDataSuccess(false);
        setIsLoader(true);
        columnVisibilityForm.resetForm();
        getStudentListBySectionId(sectionDetailsId?.value)
    }

    function getMediumList() {
        getMedium(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.medium;
                    if (data != undefined) {
                        setMediumData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setMediumData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStandard() {
        getStandardDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.standardDetails;
                    if (data != undefined) {
                        setStandardData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStandardData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSectionList(mediumId: number, standardDetailsId: number) {
        getSectionDetails(mediumId, standardDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSectionDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentListBySectionId(sectionDetailsId: number) {
        getStudentDetailsBySectionDetailsId(sectionDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetailsBySectionDetailsId;
                    if (data != undefined) {
                        setIsLoader(false);
                        setStudentDetailsData(data);
                        setIsOpenListCard(false);
                        setFilteredData1(data)
                        setDataSuccess(true);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        setDataSuccess(false);
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    setDataSuccess(true);
                    setIsOpenListCard(false);
                    setStudentDetailsData([]);
                    // setAlertStatus({ message: response.data.message, type: "error" });
                    // setIsOpen(true);
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }

    const updateUniRegNoValue = (cell: number, e: any) => {
        const newData: any = [];

        filteredData1.forEach((item: any, index: any) => {
            if (item['studentDetailsId'] == cell) {
                item['studentRollNo'] = e
            }
            newData.push(item)
        });

        setFilteredData1(newData)

        if (updateStudentRollNo.length == 0) {
            const obj = {
                studentDetailsId: cell,
                studentRollNo: e,
            };
            updateStudentRollNo.push(obj);
        } else {
            let selectedItem: any;
            updateStudentRollNo.forEach((item: any, index: any) => {
                if (item['studentDetailsId'] == cell) {
                    selectedItem = item
                    selectedItem['studentRollNo'] = e
                }
            });
            if (selectedItem != undefined) {
                selectedItem['studentRollNo'] = e
            } else {
                var obj
                obj = {
                    studentDetailsId: cell,
                    studentRollNo: e,
                };

                updateStudentRollNo.push(obj);
            }
        }
    }

    function setUniversityNumber() {
        return ({
            studentDetails: updateStudentRollNo,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function editUniversityNumberSubmit() {
        showLoader(true);
        if (updateStudentRollNo.length > 0) {
            let editUniversityNumberPostData = setUniversityNumber();
            updateRollNumberForStudent(editUniversityNumberPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset()
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false);
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (updateStudentRollNo.length == 0) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function closeAndReset() {
        setIsOpenListCard(true)
        setDataSuccess(false);
        setUpdateStudentRollNo([])
        setMediumId('')
        setStandardDetailsId('')
        setSectionDetailsId('')
    }

    return (
        <>
            <PageWrapper title="Edit Roll Number">
                <Page container='fluid'>
                    <Collapse isOpen={isOpenListCard}>
                        <Card stretch data-tour='list'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>
                                        Edit Roll Number
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className="row g-4">
                                    <div className='col-md-2'>
                                        <FormGroup id='mediumId' label='Medium' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Medium'
                                                onChange={selectMedium}
                                                value={mediumId}
                                                list={mediumData.map((data: any) => (
                                                    { value: data.mediumId, label: data.medium }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div>

                                    {mediumId?.value != undefined ? <div className='col-md-2'>
                                        <FormGroup id='standardDetailsId' label='Standard' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Standard'
                                                onChange={selectStandard}
                                                value={standardDetailsId}
                                                list={standardData.map((data: any) => (
                                                    { value: data.standardDetailsId, label: data.standardName }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null}                                  

                                    {standardDetailsId?.value != undefined ? <div className='col-md-2'>
                                        <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Section'
                                                onChange={selectSection}
                                                value={sectionDetailsId}
                                                list={sectionDetailsData.map((data: any) => (
                                                    { value: data.sectionDetailsId, label: data.sectionName }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null}

                                    {sectionDetailsId?.value != undefined ? <div className='col-3'>
                                        <Button icon='ArrowDownward' className="mt-2" color='primary' onClick={viewStudentList}>View</Button>
                                    </div> : null}
                                </div>
                            </CardBody>
                        </Card>
                    </Collapse>

                    <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                    {dataSuccess ?
                        <Card stretch data-tour='list'>

                            <CardHeader borderSize={1}>
                                <div className='d-flex col-md-5 justify-content-start'>
                                    <CardLabel icon='List' iconColor='info'>
                                        <CardTitle tag='div' className='h5'>Student List</CardTitle>
                                    </CardLabel>
                                </div>
                                <div className='d-flex col-md-7 justify-content-end'>
                                    <div className='d-flex col-8 justify-content-end' data-tour='search'>
                                        <ButtonGroup className="col-lg-8 d-print-none" color='primary'>
                                            <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                            <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                                        </ButtonGroup>
                                    </div>&nbsp;&nbsp;
                                    <div className='col-md-2 justify-content-center'>
                                        <Button color='info' icon="Save" type="submit" isDisable={updateStudentRollNo.length == 0} onClick={editUniversityNumberSubmit}>Save</Button>
                                    </div>
                                </div>
                            </CardHeader>

                            <CardBody className='table-responsive'>
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>

                                            <th scope='col' onClick={() => requestSort('admissionNo')}
                                                className='cursor-pointer text-decoration-underline'>Admission Number{' '}
                                                <Icon size='lg' className={getClassNamesFor('admissionNo')} icon='FilterList' />
                                            </th>

                                            <th scope='col' onClick={() => requestSort('studentName')}
                                                className='cursor-pointer text-decoration-underline'>Student Name{' '}
                                                <Icon size='lg' className={getClassNamesFor('studentName')} icon='FilterList' />
                                            </th>

                                            <th>Standard</th>
                                            <th>Section</th>
                                            <th>Roll Number</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {studentDetailsData != "" ?
                                            <>
                                        {filteredData.map((i: any) => (
                                            <tr key={i.studentDetailsId}>

                                                <td>{i.sno}</td>
                                                <td>{i.admissionNo}</td>
                                                <td>{i.studentName}</td>
                                                <td>{i.standardName}</td>
                                                <td>{i.sectionName}</td>
                                                <td scope='col'>
                                                    <FormGroup id='studentRollNo'>
                                                        <Input className="w-60" placeholder="" value={i.studentRollNo ? i.studentRollNo : ''} onInput={(e: any) => updateUniRegNoValue(i.studentDetailsId, e.target.value)} />
                                                    </FormGroup>
                                                </td>
                                            </tr>
                                        ))}
                                        </>
                                            :
                                            <NoDataMsg columnsCount={5} msg={noDataMsg}/>}
                                    </tbody>
                                </table>
                            </CardBody>
                            {/* <PaginationButtons
                                data={items}
                                label='items'
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            /> */}

                            {/* <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                                <div className='row m-0'>
                                    <div className='col-9 p-3'></div>
                                    <div className='col-3 p-3'>
                                        <Button color='info' icon="Save" type="submit" className='w-50' onClick={editUniversityNumberSubmit}>Save</Button>
                                    </div>
                                </div>
                            </div> */}

                        </Card>
                        : null}
                </Page>
                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper>
        </>
    )
}
export default EditRollNumber