import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getProfileForStaff = (staffDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getProfileForStaff/${getLicenseKey}/${staffDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getProfileForStaff - " + error.message)
            console.log('error caught in service : getProfileForStaff')
        },
    );

export const getStaffListByUserTypeId = (staffDetailsId: any, userTypeId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffListByUserTypeId/${getLicenseKey}/${staffDetailsId}/${userTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffListByUserTypeId - " + error.message)
            console.log('error caught in service : getStaffListByUserTypeId')
        },
    );

export const getStaffDesignation = (userTypeId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffDesignation/${getLicenseKey}/${userTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffDesignation - " + error.message)
            console.log('error caught in service : getStaffDesignation')
        },
    );

export const addStaffDesignation = (staffDesignationPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStaffDesignation`, staffDesignationPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStaffDesignation')
        }
    );

export const updateStaffDesignation = (staffDesignationPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateStaffDesignation`, staffDesignationPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStaffDesignation')
        }
    );

export const deleteStaffDesignation = (staffDesignationPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteStaffDesignation`, staffDesignationPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteStaffDesignation')
        }
    );

export const addStaffDetails = (addStaffPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStaffDetails`, addStaffPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStaffDetails')
        }
    );

export const getStaffDegree = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffDegree/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffDegree - " + error.message)
            console.log('error caught in service : getStaffDegree')
        },
    );

export const updateStaffDetails = (updateStaffDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateStaffDetails`, updateStaffDetailsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStaffDetails')
        }
    );

export const getStaffDetails = (staffDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffDetails/${getLicenseKey}/${staffDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffDetails - " + error.message)
            console.log('error caught in service : getStaffDetails')
        },
    );

export const deleteStaffDetails = (staffDeletePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteStaffDetails`, staffDeletePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteStaffDetails')
        }
    );

export const updateStaffResigningDetails = (exitOrResignPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateStaffResigningDetails`, exitOrResignPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStaffResigningDetails')
        }
    );

export const updateStaffRole = (addStaffRolePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateStaffRole`, addStaffRolePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStaffRole')
        }
    );

export const getDeletedStaffList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getDeletedStaffList/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getDeletedStaffList - " + error.message)
            console.log('error caught in service : getDeletedStaffList')
        },
    );

export const rejoinStaffDetails = (staffRejoinPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `rejoinStaffDetails`, staffRejoinPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : rejoinStaffDetails')
        }
    );

export const getStaffListByDesignationId = (staffDetailsId: any, staffDesignationId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffListByDesignationId/${getLicenseKey}/${staffDetailsId}/${staffDesignationId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffListByDesignationId - " + error.message)
            console.log('error caught in service : getStaffListByDesignationId')
        },
    );